import { StatsService } from './generated';
import { setupHeaders } from './index';

setupHeaders && setupHeaders();

export enum FrontendEvents {
  USER_APP_RUN_CLICKED = 'USER_APP_RUN_CLICKED',
  USER_APP_RUN_FAILED = 'USER_APP_RUN_FAILED',
  USER_APP_RUN_SUCCESSFULLY = 'USER_APP_RUN_SUCCESSFULLY',
  USER_APP_RUN_RESTART_FAILED = 'USER_APP_RESTART_FAILED',
  USER_CLICKED_OPEN_APP_BUTTON = 'USER_CLICKED_OPEN_APP_BUTTON',
  USER_CLICKED_SHOW_DB_BUTTON = 'USER_CLICKED_SHOW_DB_BUTTON',
  USER_CLICKED_ADD_ENV_SECRETS_BUTTON = 'USER_CLICKED_ADD_ENV_SECRETS_BUTTON',
  USER_CLICKED_TRY_TO_FIX_THIS_ERROR_BUTTON = 'USER_CLICKED_TRY_TO_FIX_THIS_ERROR_BUTTON',
  USER_SWITCHED_TO_APP_INFO_TAB = 'USER_SWTICHED_TO_APP_INFO_TAB',
  USER_SWITCHED_TO_CODE_TAB = 'USER_SWTICHED_TO_CODE_TAB',
  USER_SWITCHED_TO_ENV_SECRETS_TAB = 'USER_SWTICHED_TO_ENV_SECRETS',
  USER_SWITCHED_TO_TEST_TAB = 'USER_SWTICHED_TO_TEST_TAB',
  USER_SWITCHED_TO_VSCODE_TAB = 'USER_SWITCHED_TO_VSCODE_TAB',
  USER_SWITCHED_TO_CHAT_TAB = 'USER_SWTICHED_TO_CHAT_TAB',
  USER_PUBLISHED_APP = 'USER_PUBLISHED_APP',
  USER_PUBLISHED_APP_UPDATE = 'USER_PUBLISHED_APP_UPDATE',
  USER_PUBLISHED_TEMPLATE = 'USER_PUBLISHED_TEMPLATE',
  USER_PUBLISHED_TEMPLATE_UPDATE = 'USER_PUBLISHED_TEMPLATE_UPDATE',
  TTY_MESSAGES_RETRIEVED = 'TTY_MESSAGES_RETRIEVED',
  TTY_HISTORY_RETRIEVED = 'TTY_HISTORY_RETRIEVED',
  TTY_HISTORY_ATTEMPT = 'TTY_HISTORY_ATTEMPT',
  TTY_WEBSOCKET_ATTEMPT = 'TTY_WEBSOCKET_ATTEMPT',
  TTY_WEBSOCKET_OPENED = 'TTY_WEBSOCKET_OPENED',
  TTY_WEBSOCKET_CLOSED = 'TTY_WEBSOCKET_CLOSED',
  TTY_WEBSOCKET_FIRST_MESSAGE = 'TTY_WEBSOCKET_FIRST_MESSAGE',
  TTY_WEBSOCKET_ATTEMPTS_FAILED = 'TTY_WEBSOCKET_ATTEMPTS_FAILED',
}

export type SendEventToBackendParams = {
  eventType: FrontendEvents;
  userId?: string;
  appId?: string;
  userEmail?: string;
  orgId?: string;
  instanceId?: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  properties?: Record<string, any>;
};

export function sendEventToBackend(params: SendEventToBackendParams): void {
  try {
    StatsService.statsStoreEvent({
      event_origin: 'LAZY_FRONTEND',
      event_type: params.eventType,
      user_id: params.userId,
      user_email: params.userEmail,
      app_id: params.appId,
      org_id: params.orgId,
      instance_id: params.instanceId,
      properties: params.properties,
    }).catch((error) => {
      // eslint-disable-next-line no-console
      console.error(error);
    });
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error(error);
  }
}
