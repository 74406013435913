import React, { useCallback, useEffect, useState } from 'react';
import LazyRightArrowFillIcon from '../../icons/LazyRightArrowFillIcon';
import { isMobileDevice } from '../../utils/deviceDimensions';
import {
  TEST_ID_CHAT_TEXT_INPUT,
  TEST_ID_CHAT_SUBMIT_BUTTON,
  MAX_PROMPT_SIZE,
  TEST_ID_CHAT_MAX_PROMPT_EXCEEDED_BANNER,
} from '../../constants';
import { useChatStore } from '../../store/chat';
import { useAuthStore } from '../../store/auth';
import { Message } from '../../types';
import { debounce } from 'lodash';
import classNames from 'classnames';

interface RichPromptInputProps {
  onSend: (input: string) => void;
  currentPrompt: string;
  changeCurrentPrompt: (input: string) => void;
  placeholder?: string;
  textareaRef: React.RefObject<HTMLTextAreaElement>;
  isAppRun?: boolean;
}

const MIN_INPUT_LINES = 1;

// eslint-disable-next-line max-lines-per-function
const RichPromptInput = (props: RichPromptInputProps) => {
  const textareaRef = props.textareaRef;
  const chatMessages: Message[] = useChatStore((state) => state.messages);
  const { userPermissions } = useAuthStore();
  const [isPromptExceedingLimit, setIsPromptExceedingLimit] = useState<boolean>(false);
  const [currentPromptLength, setCurrentPromptLength] = useState<number>(0);

  const isPromptingBlocked = !userPermissions?.isUserAllowedToPrompt;

  const handleTextareaResizing = (newSize?: string) => {
    const textarea = textareaRef.current;
    if (textarea) {
      textarea.style.height = 'auto';
      textarea.style.height = newSize || `${textarea.scrollHeight}px`;
    }
  };

  // Use useCallback to memoize the debounced function
  const debouncedHandleTextareaResizing = useCallback(
    debounce((newSize?: string) => {
      handleTextareaResizing(newSize);
    }, 50),
    [] // Empty dependency array means the function is created only once
  );

  useEffect(() => {
    if (props.currentPrompt.length === 0) {
      debouncedHandleTextareaResizing();
    }
  }, [props.currentPrompt]);

  const handleInputChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    debouncedHandleTextareaResizing();
    setCurrentPromptLength(event.target.value.length);
    if (event.target.value.length > MAX_PROMPT_SIZE) {
      setIsPromptExceedingLimit(true);
    } else {
      setIsPromptExceedingLimit(false);
    }
    props.changeCurrentPrompt(event.target.value);
  };

  const handleInputKeyPress = (event: React.KeyboardEvent<HTMLTextAreaElement>) => {
    if (!isPromptExceedingLimit && !isPromptingBlocked) {
      if (isMobileDevice()) {
        if (event.key === 'Enter') {
          // do nothing
        }
      } else {
        if (event.key === 'Enter' && !event.shiftKey) {
          props.changeCurrentPrompt('');
          props.onSend(props.currentPrompt);
          handleTextareaResizing('auto');
        }
      }
    }
  };

  const handleButtonClick = (_event: React.MouseEvent<HTMLButtonElement>) => {
    if (!isPromptExceedingLimit && !isPromptingBlocked) {
      props.changeCurrentPrompt('');
      props.onSend(props.currentPrompt);
      handleTextareaResizing('auto');
    }
  };

  const placeholderText = props.isAppRun
    ? 'Enter input'
    : chatMessages.length > 1
    ? 'Describe your changes'
    : 'Or describe app to build';

  const promptingBlockedMessage = 'You ran out of prompts - upgrade your plan';

  return (
    <div className="flex flex-col">
      {isPromptExceedingLimit && (
        <div
          className="flex bg-system-danger 
        font-medium text-white justify-center rounded-t-lg py-[6px] px-2"
          data-testid={TEST_ID_CHAT_MAX_PROMPT_EXCEEDED_BANNER}
        >
          {currentPromptLength.toString()}/ {MAX_PROMPT_SIZE}. The prompt is too long
        </div>
      )}
      <div className="flex w-full flex-row border border-transparent p-1 rounded-lg shadow-around">
        <textarea
          ref={textareaRef}
          rows={MIN_INPUT_LINES}
          className="flex-1 mr-2 p-1 border-0 focus:outline-0 focus:outline"
          value={props.currentPrompt}
          onChange={handleInputChange}
          onKeyDown={handleInputKeyPress}
          style={{ resize: 'none', maxHeight: 200 }}
          placeholder={isPromptingBlocked ? promptingBlockedMessage : placeholderText}
          data-testid={TEST_ID_CHAT_TEXT_INPUT}
          disabled={isPromptingBlocked}
        ></textarea>
        <button
          className={classNames('text-gray-400 hover:text-gray-600', {
            'text-gray-500': props.currentPrompt.length !== 0,
            'hover:text-gray-400 cursor-not-allowed': props.currentPrompt.length === 0,
            'disabled cursor-not-allowed': isPromptExceedingLimit || isPromptingBlocked,
          })}
          style={{
            marginTop: 'auto',
            marginLeft: 'auto',
            marginBottom: '0.15rem',
            color: '#DC2626',
          }}
          onClick={(e) => {
            handleButtonClick(e);
            handleTextareaResizing();
          }}
          data-testid={TEST_ID_CHAT_SUBMIT_BUTTON}
        >
          {props.currentPrompt && (
            <LazyRightArrowFillIcon
              color={isPromptExceedingLimit || isPromptingBlocked ? '#DC2626' : '#007BFF'}
            />
          )}
        </button>
      </div>
    </div>
  );
};

export default RichPromptInput;
