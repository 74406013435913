import React from 'react';
import ReactMarkdown from 'react-markdown';
import Linkify from 'react-linkify';

import { TextMessageContentFormat } from '../../../types';
import classNames from 'classnames';
import { isMobileDevice } from '../../../utils/deviceDimensions';
import ImageLoader from '../../ImageLoader';

interface TextMessageProps {
  text: string;
  format: TextMessageContentFormat;
}

// eslint-disable-next-line max-lines-per-function
const TextMessage = ({ text, format }: TextMessageProps) => {
  const urlDecorator = (href: string, text: string, key: number) => {
    const isImageLink = /\.(png|jpg|jpeg|gif|bmp|svg)/i.test(text);
    const isFile = /\.(py|js|txt)/.test(text);
    const isLocalHostHref = /http(s)?:\/\/\d{1,}\.\d{1,}\.\d{1,}\.\d{1,}:\d+/.test(href);
    if (isImageLink) {
      return (
        <a href={href} target="_blank" key={key}>
          <div
            className={classNames({
              'h-[200px] w-[200px]': isMobileDevice(),
              'h-[400px] w-[400px]': !isMobileDevice(),
            })}
          >
            <ImageLoader src={href} maxTrials={5}></ImageLoader>
          </div>
        </a>
      );
    } else if (isFile) {
      return text;
    } else if (isLocalHostHref) {
      return text;
    } else {
      return (
        <a href={href} target="_blank" key={key} className="text-lazy-action">
          {text}
        </a>
      );
    }
  };

  switch (format) {
    case TextMessageContentFormat.Markdown:
      return <ReactMarkdown className="chatMessageMarkdown">{text}</ReactMarkdown>;
    case TextMessageContentFormat.Plain:
      return (
        <span>
          <Linkify componentDecorator={urlDecorator}>{text}</Linkify>
        </span>
      );
  }
};

export default TextMessage;
