import classNames from 'classnames';
import React, { useState, useEffect } from 'react';
import { isMobileDevice } from '../../utils/deviceDimensions';

const REACTIVATION_DELAY = 3000; // 3 seconds

export interface RichPromptAction {
  content: React.ReactNode;
  onClick: () => void;
  className?: string;
  disabled?: boolean;
  hasReactivationDelay?: boolean;
}

interface RichPromptActionListProps {
  actions: RichPromptAction[];
}

// eslint-disable-next-line max-lines-per-function
const RichPromptActionList = ({ actions: initialActions }: RichPromptActionListProps) => {
  if (!initialActions?.length) {
    return <></>;
  }

  const [actions, setActions] = useState(initialActions);

  useEffect(() => {
    setActions(initialActions);
  }, [initialActions]);

  const handleActionClick = (action: RichPromptAction, index: number) => {
    if (action.hasReactivationDelay && !action.disabled) {
      const newActions = actions.map((a, idx) => {
        if (idx === index) {
          return { ...a, disabled: true };
        }
        return a;
      });
      setActions(newActions);

      setTimeout(() => {
        const newActions = actions.map((a, idx) => {
          if (idx === index) {
            return { ...a, disabled: false };
          }
          return a;
        });
        setActions(newActions);
      }, REACTIVATION_DELAY);
    }
    action.onClick();
  };

  return (
    <div
      className={classNames('flex flex-col w-full py-1.5', {
        'gap-[2px]': !isMobileDevice(),
        'gap-[4px]': isMobileDevice(),
      })}
    >
      {actions.map((action, index) => (
        <button
          key={index}
          disabled={action.disabled}
          className={classNames(
            'flex py-1.5 rounded-md',
            {
              'text-system-success hover:bg-system-success-hover': index === 0,
              'text-system-accent hover:bg-system-accent-hover': index !== 0,
              '!text-label-tertiary !hover:bg-label-tertiary !cursor-not-allowed': action.disabled,
            },
            action.className
          )}
          onClick={() => handleActionClick(action, index)}
          onMouseDown={(e) => e.preventDefault()}
        >
          {action.content}
        </button>
      ))}
    </div>
  );
};

export default RichPromptActionList;
