import { useEffect, useRef, useState } from 'react';

const useModalVisibility = (initialVisibility: boolean) => {
  const itemRef = useRef<HTMLElement>(null);
  const [isVisible, setIsVisible] = useState(initialVisibility);

  const handleDocumentClick = (event: MouseEvent) => {
    if (event.target && itemRef.current && !itemRef.current.contains(event.target as HTMLElement)) {
      setIsVisible(false);
    }
  };

  useEffect(() => {
    document.addEventListener('click', handleDocumentClick, true);
    return () => {
      document.removeEventListener('click', handleDocumentClick, true);
    };
  }, []);

  return { itemRef, isVisible, setIsVisible };
};

export default useModalVisibility;
