/* eslint-disable max-len */
import { useAppStore } from '../store/app';
import {
  AppStatusChangeMessageContent,
  AuthenticationRequirementToMessageContent,
  Message,
  MessageSourceType,
  MessageType,
  TextMessageContent,
  TextMessageContentFormat,
} from '../types';
import { AppInstanceState, AuthenticationRequirement } from './generated';
import { useChatStore } from '../store/chat';
import { verifySignatureForLazyInternalMessage } from './InternalLazyMessagesHelper';
import { v4 as uuidv4 } from 'uuid';
import { useAuthStore } from '../store/auth';
import { sleep } from './utils';
import { TTYMessage, getTTYHistory, getTTYHistoryFromFile } from './TtyHelper';
import { Mutex } from 'async-mutex';
import { FrontendEvents, sendEventToBackend } from './StatsApi';
import LazyTestUserAvatar from '../assets/test-user-profile.svg';

export const SOURCE_STDOUT = 'stdout';
export const SOURCE_STDERR = 'stderr';
export const SOURCE_STDIN = 'stdin';
export const SOURCE_EXCEPTION = 'exception';
export const SOURCE_INPUT_PROMPT = 'input_prompt';
export const SOURCE_APP_STATE = 'app_state';
export const SOURCE_EXECUTION_POINT = 'app_execution_point';
export const SOURCE_APP_SERVER_STATUS = 'app_server_status';
export const SOURCE_LAZY_INTERNAL = 'lazy_internal';
export const SOURCE_LAZY_TTY_CAPABILITIES = 'lazy_tty_capabilities';
export const LAZY_APP_LISTENING_URL = 'lazy_app_listening_url';
export const LAZY_APP_SQLITE_WEB_URL = 'lazy_app_sqlite_web_url';
export const LAZY_APP_STARTING_INFO = 'lazy_app_starting_info';
export const LAZY_REPORT_ON_ERRORS_FROM_JAVASCRIPT = 'report_javascript_error';

export const MESSAGE_SOURCES_WITHOUT_DEDICATED_QUEUES = new Set([
  SOURCE_STDOUT,
  SOURCE_STDERR,
  SOURCE_STDIN,
  SOURCE_EXCEPTION,
  SOURCE_INPUT_PROMPT,
  LAZY_APP_STARTING_INFO,
]);

export const REGULAR_MESSAGE_SOURCES = [
  SOURCE_STDOUT,
  SOURCE_STDERR,
  SOURCE_STDIN,
  SOURCE_EXCEPTION,
  SOURCE_INPUT_PROMPT,
  LAZY_APP_LISTENING_URL,
  LAZY_APP_SQLITE_WEB_URL,
  LAZY_APP_STARTING_INFO,
  LAZY_REPORT_ON_ERRORS_FROM_JAVASCRIPT,
];

const TIME_MS_TO_SLEEP_BETWEEN_HISTORY_RETRIEVAL_ATTEMPTS = 2000;

export type StatusMessageType = string | null | undefined;

const messageHandlingMutex = new Mutex();

const getMessageTimestamp = (ttyMessage: TTYMessage): Date => {
  if (ttyMessage.timestamp.includes('+')) {
    return new Date(ttyMessage.timestamp);
  }
  return new Date(`${ttyMessage.timestamp}${ttyMessage.timestamp.endsWith('Z') ? '' : 'Z'}`);
};

export function getTimestampForOldestMessage(messages: Message[]): Date | undefined {
  for (const message of messages) {
    const textMessageSource = (message.content as TextMessageContent)?.source;
    if (textMessageSource && MESSAGE_SOURCES_WITHOUT_DEDICATED_QUEUES.has(textMessageSource)) {
      return message.sentAt;
    }
  }
  return undefined;
}

// eslint-disable-next-line max-lines-per-function
export const convertFromTTYToMessage = async (ttyMessage: TTYMessage): Promise<Message | null> => {
  const messageTimestamp = getMessageTimestamp(ttyMessage);
  if (ttyMessage.source === SOURCE_APP_STATE) {
    const content: AppStatusChangeMessageContent = {
      type: MessageType.AppStatusChange,
      state: ttyMessage.message as unknown as AppInstanceState,
    };

    return {
      id: ttyMessage.id,
      content,
      source: {
        type: MessageSourceType.App,
        avatarUrl: useAppStore.getState().appImageURLMap,
        name: useAppStore.getState().appName,
      },
      sentAt: new Date(messageTimestamp),
    };
  } else if (ttyMessage.source === SOURCE_EXECUTION_POINT) {
    /* empty */
  } else if (ttyMessage.source === SOURCE_LAZY_INTERNAL) {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    const contentAndMessageSignature = JSON.parse(ttyMessage.message);
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access
    const signature: string = contentAndMessageSignature.signature;
    if (
      !(await verifySignatureForLazyInternalMessage(
        (contentAndMessageSignature as { message: string; signature: string }).message,
        signature
      ))
    ) {
      return null;
    }
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-argument
    const request: AuthenticationRequirement = JSON.parse(contentAndMessageSignature.message);
    const content = {
      type: MessageType.AuthenticationRequirement,
      provider: request.provider,
      authType: request.auth_type,
      oauthSigninLink: request.oauth_signin_link,
      oauthSignedInUser: request.oauth_signed_in_user,
      redactedApiKey: request.redacted_api_key,
      fields: request.fields,
    } as AuthenticationRequirementToMessageContent;

    const result = {
      id: ttyMessage.id,
      content,
      source: {
        type: MessageSourceType.App,
        avatarUrl: useAppStore.getState().appImageURLMap,
        name: useAppStore.getState().appName,
      },
      sentAt: new Date(messageTimestamp),
    };
    return result;
  } else if (REGULAR_MESSAGE_SOURCES.includes(ttyMessage.source)) {
    const content: TextMessageContent = {
      type: MessageType.Text,
      text: ttyMessage.message,
      format: TextMessageContentFormat.Plain,
      isErrorTraceback:
        ttyMessage.source === SOURCE_EXCEPTION ||
        ttyMessage.source === LAZY_REPORT_ON_ERRORS_FROM_JAVASCRIPT,
      isAppUrl: ttyMessage.source === LAZY_APP_LISTENING_URL,
      isSqliteWebUrl: ttyMessage.source === LAZY_APP_SQLITE_WEB_URL,
      source: ttyMessage.source,
    };
    return {
      id: ttyMessage.id,
      content,
      source: {
        type: MessageSourceType.App,
        avatarUrl:
          ttyMessage.source === SOURCE_STDIN
            ? useAuthStore.getState().firebaseUser?.photoURL || (LazyTestUserAvatar as string)
            : useAppStore.getState().appImageURLMap,
        name:
          ttyMessage.source === SOURCE_STDIN
            ? useAuthStore.getState().firebaseUser?.displayName ||
              useAuthStore.getState().firebaseUser?.email
            : useAppStore.getState().appName,
      },
      sentAt: new Date(messageTimestamp),
    };
  }
  return null;
};

export const processMessageForActions = (
  ttyMessage: TTYMessage,
  setOpenAppUrlFromMessages: (url: string | null) => void,
  setSqliteWebUrlFromMessages: (url: string | null) => void,
  updateAppState?: (newAppState: AppInstanceState, timestamp: Date | null) => void
) => {
  if (ttyMessage.source === SOURCE_INPUT_PROMPT) {
    useChatStore.setState({
      userInputBlocked: false,
      userInputLoading: false,
    });
  } else if (ttyMessage.source === SOURCE_APP_STATE) {
    if (updateAppState) {
      updateAppState(ttyMessage.message as AppInstanceState, getMessageTimestamp(ttyMessage));
    }
  } else if (ttyMessage.source === LAZY_APP_LISTENING_URL) {
    setOpenAppUrlFromMessages(ttyMessage.message);
  } else if (ttyMessage.source === LAZY_APP_SQLITE_WEB_URL) {
    setSqliteWebUrlFromMessages(ttyMessage.message);
  } else if (ttyMessage.source === SOURCE_STDIN) {
    // A user message was sent to the app, so it should no longer be waiting for user
    // input. (If it was sent by this client, sendUserMessageToApp already sets
    // userInputBlocked, so this is mainly in case it was sent by another client.)
    useChatStore.setState({
      userInputBlocked: true,
    });
  }
};

// eslint-disable-next-line max-lines-per-function
export const processHistoryForActions = (
  history: TTYMessage[],
  setOpenAppUrlFromMessages: (url: string | null) => void,
  setSqliteWebUrlFromMessages: (url: string | null) => void,
  updateAppState?: (newAppState: AppInstanceState, timestamp: Date | null) => void
) => {
  let timestampLastStdin: Date = new Date(1970);
  for (let i = history.length - 1; i >= 0; --i) {
    if (history[i].source === SOURCE_STDIN) {
      timestampLastStdin = getMessageTimestamp(history[i]);
      break;
    }
  }

  const timestampLastAppRunning: Date = new Date(1970);
  for (let i = history.length - 1; i >= 0; --i) {
    if (history[i].source === SOURCE_APP_STATE) {
      if (history[i].message === AppInstanceState.RUNNING) {
        timestampLastStdin = getMessageTimestamp(history[i]);
        break;
      }
    }
  }

  // It only needs to catch up with the last message for input and only if not answered yet.
  for (let i = history.length - 1; i >= 0; --i) {
    if (
      [SOURCE_INPUT_PROMPT, LAZY_APP_LISTENING_URL, LAZY_APP_SQLITE_WEB_URL].includes(
        history[i].source
      )
    ) {
      if (
        getMessageTimestamp(history[i]) >= timestampLastStdin &&
        getMessageTimestamp(history[i]) >= timestampLastAppRunning
      ) {
        processMessageForActions(
          history[i],
          setOpenAppUrlFromMessages,
          setSqliteWebUrlFromMessages,
          updateAppState
        );
      }
      if (history[i].source === SOURCE_INPUT_PROMPT) {
        break;
      }
    }
  }
};

export const capitalize = (str: string): string => {
  if (!str || typeof str !== 'string') {
    return str;
  }

  return str[0].toUpperCase() + str.slice(1);
};

const executionPointToUserReadableStatus = (status: string): string | null => {
  const appStatus = JSON.parse(status) as { function_name: string };
  const functionName = appStatus.function_name;

  if (functionName === '<module>' || functionName === 'main') {
    // Top-level code or a main() function, which don't have any meaningful info.
    return '';
  }

  // eslint-disable-next-line @typescript-eslint/restrict-plus-operands, @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call
  return capitalize(functionName.replace(/_/g, ' ')) + '...';
};

export const isPendingInput = () => {
  return !useChatStore.getState().userInputBlocked;
};

export const convertAndCallHook = async (
  messageHook: (msg: Message[] | null, statusMessage: StatusMessageType) => void,
  history: TTYMessage[],
  isLoadingPreviousMessage = false
) => {
  const messagePromises = history.map((ttyMessage) => convertFromTTYToMessage(ttyMessage));
  // eslint-disable-next-line compat/compat
  const messages = await Promise.all(messagePromises);
  const filteredMessages = messages.filter((e): e is Message => e !== null);
  messageHook(filteredMessages, undefined);

  // set the last status
  if (isPendingInput() || isLoadingPreviousMessage) {
    return;
  }
  for (let i = history.length - 1; i >= 0; --i) {
    if (history[i].source === SOURCE_EXECUTION_POINT) {
      const converted = executionPointToUserReadableStatus(history[i].message);
      messageHook(null, converted);
      break;
    }
  }
};

export const filterFromLatestCheckpoint = (messages: TTYMessage[]): TTYMessage[] => {
  let latestCheckPointIndex = 0;
  // The first message on a run is INSTALLING_DEPENDENCIES but adding the STOPPED ones too in case
  // the frontend reads even before that one arrives so to not cause confusion with messages from previous run.
  const CHECKPOINT_MESSAGES = [
    AppInstanceState.INSTALLING_DEPENDENCIES,
    AppInstanceState.STOPPED,
    AppInstanceState.STOPPING,
    AppInstanceState.STOPPED_AUTOMATICALLY,
  ];
  for (let i = messages.length - 1; i >= 0; --i) {
    if (
      messages[i].source === SOURCE_APP_STATE &&
      CHECKPOINT_MESSAGES.includes(messages[i].message as AppInstanceState)
    ) {
      latestCheckPointIndex = i;
      break;
    }
  }
  return messages.slice(latestCheckPointIndex);
};

// eslint-disable-next-line max-lines-per-function, max-statements
export const catchUpWithPreviousMessages = async (
  instanceId: string,
  messageHook: (msg: Message[] | null, statusMessage: StatusMessageType) => void,
  idsOfPreviouslyProcessedMessages: React.MutableRefObject<Set<string>>,
  setOpenAppUrlFromMessages: (url: string | null) => void,
  setSqliteWebUrlFromMessages: (url: string | null) => void,
  latestRunOnly: boolean,
  beforeTimestamp?: Date
  // eslint-disable-next-line max-params
): Promise<boolean> => {
  try {
    const t0 = Date.now();
    let rawHistory: TTYMessage[] = (await getTTYHistory(instanceId, beforeTimestamp)).messages;
    if (latestRunOnly) {
      rawHistory = filterFromLatestCheckpoint(rawHistory);
    }
    const t1 = Date.now();

    const releaseMutex = await messageHandlingMutex.acquire();
    try {
      const history: TTYMessage[] = rawHistory.filter(
        (entry) => !idsOfPreviouslyProcessedMessages.current.has(entry.id)
      );

      sendEventToBackend({
        eventType: FrontendEvents.TTY_MESSAGES_RETRIEVED,
        instanceId,
        properties: {
          eventTime: t0,
          eventDuration: t1 - t0,
          previouslyProcessedCount: idsOfPreviouslyProcessedMessages.current.size,
          rawCount: rawHistory.length,
          rawFirstMessageId: rawHistory[0]?.id,
          rawFirstMessageTimestamp: rawHistory[0]?.timestamp,
          rawLastMessageId: rawHistory[rawHistory.length - 1]?.id,
          rawLastMessageTimestamp: rawHistory[rawHistory.length - 1]?.timestamp,
          newCount: history.length,
          newFirstMessageId: history[0]?.id,
          newFirstMessageTimestamp: history[0]?.timestamp,
          newLastMessageId: history[history.length - 1]?.id,
          newLastMessageTimestamp: history[history.length - 1]?.timestamp,
        },
      });

      history.forEach((entry) => idsOfPreviouslyProcessedMessages.current.add(entry.id));

      // Skip processing for actions when loading previous messages
      if (!beforeTimestamp) {
        processHistoryForActions(history, setOpenAppUrlFromMessages, setSqliteWebUrlFromMessages);
      }
      await convertAndCallHook(messageHook, history, !!beforeTimestamp);
      return history.length > 0;
    } finally {
      releaseMutex();
    }
  } catch {
    /* ignoring error */
    return false;
  }
};

export const sendUserMessageToApp = async (
  webSocket: WebSocket,
  message: string,
  idsOfPreviouslyProcessedMessages: React.MutableRefObject<Set<string>>,
  messageHook: (msg: Message[], statusMessage: string | null) => void
) => {
  const messageId = (uuidv4 as () => string)();
  const ttyMessage: TTYMessage = {
    timestamp: new Date().toISOString(),
    // eslint-disable-next-line @typescript-eslint/no-unsafe-call
    id: messageId,
    message,
    source: SOURCE_STDIN,
  };
  webSocket.send(JSON.stringify(ttyMessage));
  idsOfPreviouslyProcessedMessages.current.add(messageId);
  await convertAndCallHook(messageHook, [ttyMessage], false);
  // Show an empty loading bar while the message is in transit.
  useChatStore.setState({
    userInputBlocked: true,
    userInputLoading: true,
    statusMessage: '',
  });
};

function getLastXMessages(rawHistory: TTYMessage[]): TTYMessage[] {
  // NOTE: This setting is also on tty server. Change at both places same time
  const DEFAULT_MESSAGES_PAGE_SIZE = 200;
  // Find the index of the last message with the specified criteria
  const reveresedLastXmessages = rawHistory.slice(-DEFAULT_MESSAGES_PAGE_SIZE).reverse();
  const startingMessageIndex = reveresedLastXmessages.findIndex(
    (message) => message.message === 'installing_dependencies' && message.source === 'app_state'
  );

  if (startingMessageIndex !== -1) {
    // If the "starting" message is found in the last DEFAULT_MESSAGES_PAGE_SIZE messages
    // Return only the messages after that entry
    return rawHistory
      .slice(-DEFAULT_MESSAGES_PAGE_SIZE)
      .slice(reveresedLastXmessages.length - startingMessageIndex + 1);
  } else {
    // If the "starting" message is not found in the last DEFAULT_MESSAGES_PAGE_SIZE messages
    // Return the last DEFAULT_MESSAGES_PAGE_SIZE messages
    return rawHistory.slice(-DEFAULT_MESSAGES_PAGE_SIZE);
  }
}

// eslint-disable-next-line max-lines-per-function, max-params, max-statements
export async function catchUpWithPreviousMessagesSavedOnLogs(
  instanceId: string,
  messageHook: (msg: Message[] | null, statusMessage: string | null | undefined) => void,
  idsOfPreviouslyProcessedMessages: React.MutableRefObject<Set<string>>,
  lastMessageFromHistoryFile: React.MutableRefObject<string | null>,
  latestRunOnly = false
): Promise<string | null> {
  const t0 = Date.now();
  sendEventToBackend({
    eventType: FrontendEvents.TTY_HISTORY_ATTEMPT,
    instanceId,
    properties: {
      eventTime: t0,
    },
  });

  try {
    let rawHistory: TTYMessage[] = getLastXMessages(
      (await getTTYHistoryFromFile(instanceId)).messages
    );
    if (latestRunOnly) {
      rawHistory = filterFromLatestCheckpoint(rawHistory);
    }
    const t1 = Date.now();

    const history: TTYMessage[] = rawHistory.filter(
      (entry) => !idsOfPreviouslyProcessedMessages.current.has(entry.id)
    );

    sendEventToBackend({
      eventType: FrontendEvents.TTY_HISTORY_RETRIEVED,
      instanceId,
      properties: {
        eventTime: t0,
        eventDuration: t1 - t0,
        previouslyProcessedCount: idsOfPreviouslyProcessedMessages.current.size,
        rawCount: rawHistory.length,
        rawFirstMessageId: rawHistory[0]?.id,
        rawFirstMessageTimestamp: rawHistory[0]?.timestamp,
        rawLastMessageId: rawHistory[rawHistory.length - 1]?.id,
        rawLastMessageTimestamp: rawHistory[rawHistory.length - 1]?.timestamp,
        newCount: history.length,
        newFirstMessageId: history[0]?.id,
        newFirstMessageTimestamp: history[0]?.timestamp,
        newLastMessageId: history[history.length - 1]?.id,
        newLastMessageTimestamp: history[history.length - 1]?.timestamp,
      },
    });

    const releaseMutex = await messageHandlingMutex.acquire();
    try {
      history.forEach((entry) => {
        idsOfPreviouslyProcessedMessages.current.add(entry.id);
      });

      if (history.length > 0) {
        lastMessageFromHistoryFile.current = history[history.length - 1].id;
        await convertAndCallHook(messageHook, history, true);
      }
      return lastMessageFromHistoryFile.current;
    } finally {
      releaseMutex();
    }
  } catch (e) {
    const t1 = Date.now();
    sendEventToBackend({
      eventType: FrontendEvents.TTY_HISTORY_RETRIEVED,
      instanceId,
      properties: {
        // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
        error: `${e}`,
        eventTime: t0,
        eventDuration: t1 - t0,
      },
    });

    /* ignoring error */
    await sleep(TIME_MS_TO_SLEEP_BETWEEN_HISTORY_RETRIEVAL_ATTEMPTS);
  }
  return null;
}

export function sendStderrTextMessageToMessageHook(
  message: string,
  showTryToFixItButton: boolean,
  messageHook: (
    msg: Message[] | null,
    statusMessage: string | null | undefined,
    setAppStatusKnown: boolean
  ) => void
) {
  const content: TextMessageContent = {
    type: MessageType.Text,
    text: message,
    format: TextMessageContentFormat.Plain,
    isErrorTraceback: showTryToFixItButton,
    isAppUrl: false,
    isSqliteWebUrl: false,
    source: showTryToFixItButton ? 'STDERR' : 'STDOUT',
  };
  messageHook(
    [
      {
        id: (uuidv4 as () => string)(),
        content,
        source: {
          type: MessageSourceType.App,
          avatarUrl: useAppStore.getState().appImageURLMap,
          name: useAppStore.getState().appName,
        },
        sentAt: new Date(),
      } as Message,
    ],
    null,
    false
  );
}
