import { OpenAPI } from './generated/core/OpenAPI';
import { request } from './generated/core/request';
import type { CancelablePromise } from './generated/core/CancelablePromise';

export type TTYMessage = {
  timestamp: string;
  id: string;
  message: string;
  source: string;
};

export type ResponseWithTTYMessage = {
  messages: TTYMessage[];
};

export const getTTYHistory = (
  instanceId: string,
  beforeTimestamp?: Date
): CancelablePromise<ResponseWithTTYMessage> => {
  return request(OpenAPI, {
    method: 'GET',
    url: `/tty/${instanceId}/api/v1/messages`,
    query: { before_timestamp: beforeTimestamp?.toISOString() },
  });
};

export const getTTYHistoryFromFile = (instanceId: string): Promise<ResponseWithTTYMessage> => {
  // Try retrieving the history from GCS if the direct file access fails.
  return request<ResponseWithTTYMessage>(OpenAPI, {
    method: 'GET',
    url: `/api/v1/instance/${instanceId}/tty_history`,
  });
};

export const getAuthCookie = (): CancelablePromise<ResponseWithTTYMessage> => {
  return request(OpenAPI, {
    method: 'GET',
    url: `/api/v1/user/cookie`,
  });
};

export const isAuthenticatedForProvider = async (
  instanceId: string,
  providerName: string
): Promise<boolean> => {
  try {
    const response: { result: string } = await request(OpenAPI, {
      method: 'GET',
      url: `/api/v1/instance/${instanceId}/is_authenticated/${providerName}`,
    });
    return response.result === 'yes';
  } catch {
    return false;
  }
};
