export const BUILDER_VIEW_CHAT_TAB_BUTTON = 'builder-view-chat-tab-button';
export const BUILDER_VIEW_APP_INFO_TAB_BUTTON = 'builder-view-app-info-tab-button';
export const BUILDER_VIEW_CODE_TAB_BUTTON = 'builder-view-code-tab-button';
export const BUILDER_VIEW_SECRETS_TAB_BUTTON = 'builder-view-secrets-tab-button';
export const BUILDER_VIEW_TEST_TAB_BUTTON = 'builder-view-test-tab-button';
export const BUILDER_VIEW_APP_LOGIC_TEAM_LABEL = 'builder-view-app-logic-team-label';
export const BUILDER_VIEW_MOVE_APP_TO_TEAM_BUTTON = 'builder-view-move-app-to-team-button';
export const BUILDER_VIEW_MOVE_APP_TO_TEAM_CONFIRMATION_BUTTON =
  'builder-view-move-app-to-team-confirmation-button';

// Secrets tab
export const ENV_SECRETS_SECRET_NAME_EDIT = 'env_secrets_secret_name_edit';
export const ENV_SECRETS_TEST_VALUE_EDIT = 'env_secrets_test_value_edit';
export const ENV_SECRETS_PROD_VALUE_EDIT = 'env_secrets_prod_value_edit';
export const ENV_SECRETS_DESCRIPTION_EDIT = 'env_secrets_description_edit';
export const ENV_SECRETS_DIFFERENT_VALUES_SWITCH = 'env_secrets_different_values_switch';
export const ENV_SECRETS_EDIT_A_SECRET_BUTTON = 'env_secrets_edit_a_secret_button';
export const ENV_SECRETS_DELETE_A_SECRET_BUTTON = 'env_secrets_delete_a_secret_button';
export const ENV_SECRETS_ADD_NEW_SECRET_BUTTON = 'env_secrets_add_new_secret_button';
export const ENV_SECRETS_LOADING_SPINNER = 'env_secrets_loading_spinner';

export const APP_LOGIC_APP_NAME = 'app_logic_app_name';
export const APP_RUN_HEADER_APP_NAME = 'app-run-header-app-name';
export const APP_PRODUCTION_VERSION_LINK = 'app-production-version-link';

export const PUBLISH_PRODUCTION_APP_MODAL = 'publish-production-app-modal';
export const CLOSE_PUBLISH_PRODUCTION_APP_MODAL = 'close-publish-production-app-modal';
export const PUBLISH_APP_BUTTON = 'publish-app-button';

export const TEST_ID_SIDEBAR_BUTTON = 'sidebar-test-id';
export const TEST_ID_CHAT_TEXT_INPUT = 'chat-text-input-test-id';
export const TEST_ID_CHAT_SUBMIT_BUTTON = 'chat-submit-button-test-id';
export const TEST_ID_CHAT_MAX_PROMPT_EXCEEDED_BANNER = 'chat-max-prompt-exceeded-banner';
export const TEST_ID_CHAT_STOP_BUTTON = 'chat-stop-button-test-id';
export const TEST_ID_SIDEBAR_ACTIVE_APP_ACTION_BUTTON = 'sidebar-active-app-action-button-test-id';
export const TEST_ID_LOADING_BAR = 'loading-bar-test-id';
export const TEST_ID_STARTER_PROMPTS = 'starter-prompts-test-id';

// Team management popup
export const TEST_ID_NEW_TEAM_NAME_INPUT = 'new-team-name-input-test-id';
export const TEST_ID_CREATE_NEW_TEAM_BUTTON = 'create-new-team-button-test-id';
export const TEST_ID_CREATE_NEW_TEAM_SKIP_BUTTON = 'create-new-team-skip-button-test-id';
export const TEST_ID_TEAM_USER_INVITE_INPUT = 'invite-user-to-team-input-test-id';
export const TEST_ID_TEAM_ADD_ANOTHER_USER_INVITE_BUTTON =
  'add-another-user-invite-to-team-button-test-id';
export const TEST_ID_TEAM_REMOVE_INVITE_INPUT_BUTTON = 'remove-user-invite-to-team-button-test-id';
export const TEST_ID_TEAM_SEND_INVITES_BUTTON = 'send-invites-to-team-button-test-id';
export const TEST_ID_TEAM_POP_UP_CLOSE_BUTTON = 'team-popup-close-button-test-id';
export const TEST_ID_TEAM_NAME_CONTAINER = 'team-name-container-test-id';
export const TEST_ID_TEAM_NAME_INPUT = 'team-name-input-test-id';
export const TEST_ID_TEAM_CHANGE_NAME_BUTTON = 'change-team-name-button-test-id';
export const TEST_ID_TEAM_SAVE_CHANGED_NAME_BUTTON = 'save-changed-team-name-button-test-id';
export const TEST_ID_TEAM_CANCEL_CHANGED_NAME_BUTTON = 'cancel-changed-team-name-button-test-id';
export const TEST_ID_TEAM_MEMBERS_COUNT_CONTAINER = 'team-members_count-container-test-id';
export const TEST_ID_TEAM_INVITES_CONTAINER = 'team-invites-container-test-id';
export const TEST_ID_TEAM_INVITE_EMAIL_CONTAINER = 'team-invite-email-container-test-id';
export const TEST_ID_TEAM_INVITE_STATUS_CONTAINER = 'team-invite-status-container-test-id';
export const TEST_ID_TEAM_INVITE_RESEND_BUTTON = 'team-invite-resend-button-test-id';
export const TEST_ID_TEAM_INVITE_REMOVE_BUTTON = 'team-invite-remove-button-test-id';
export const TEST_ID_TEAM_INVITE_REMOVE_CONFIRMATION_BUTTON =
  'team-invite-remove-confirmation-button-test-id';

// Auth navbar with account switcher and create new team button
export const TEST_ID_AUTH_NAV_DROPDOWN_BUTTON = 'auth-nav-dropdown-button-test-id';
export const TEST_ID_AUTH_NAV_CREATE_NEW_TEAM_BUTTON = 'auth-nav-create-new-team-button-test-id';
export const TEST_ID_AUTH_NAV_SWITCH_TEAM_BUTTON = 'auth-nav-switch-team-button-test-id';
export const TEST_ID_AUTH_NAV_TEAM_NAME_LABEL = 'auth-nav-team-name-label-test-id';
export const TEST_ID_AUTH_NAV_MANAGE_TEAM_BUTTON = 'auth-nav-manage-team-button-test-id';
export const TEST_ID_CODE_LISTING_FILES = 'code-listing-files';

export const TEST_APP_RUN_APP_BUTTON = 'run-app-button';
export const TEST_APP_STOP_APP_BUTTON = 'stop-app-button';
export const TEST_APP_RUN_AGAIN_APP_BUTTON = 'run-again-app-button';
export const TEST_APP_AUTO_SHUTDOWN_TIMER = 'app-auto-shut-down-timer';
export const TEST_APP_OPEN_APP_BUTTON = 'open-app-button';
export const TEST_APP_VIEW_DATABASES_APP_BUTTON = 'view-databases-button';
export const TEST_APP_RESTART_TO_UPDATE_APP_BUTTON = 'restart-to-update-app-button';
export const TEST_UPGRADE_TO_PRO_BUTTON = 'test-upgrade-to-pro-button';
export const TEST_APP_LOADER = 'loader';
export const TEST_RUN_APP_OLDER_VERSION_BUTTON = 'run-app-older-version-button';

export const TEST_LOAD_PREVIOUS_MESSAGES_BUTTON = 'load-previous-messages-button';

// App not found page
export const APP_NOT_FOUND_TITLE = 'app-not-found';

// local storage keys
export const INITIAL_PROMPT_KEY = 'init_prompt';
export const CURRENT_ACTIVE_APP_ID = 'current_active_app_id';

export const PRICING_URL = 'https://getlazy.ai/pricing';

export const LAZY_ACCOUNTS_DOMAIN = 'https://accounts.getlazy.ai';
export const LAZY_PLAN_PAGE = '/plan';
export const LAZY_USER_PROFILE_PAGE = '/profile';

// Top bar
export const TOP_BAR_APP_NAME = 'top-bar-app-name';
export const TOP_BAR_APP_RUNNING_STATE = 'top-bar-app-running-state';
export const TOP_BAR_VIEW_DATABASE_BUTTON = 'top-bar-view-database-button';

// vscode
export const SAVE__CODE_BUTTON_TEST_ID = 'save-code';
export const CLEAR_CODE_CHANGES_BUTTON_TEST_ID = 'clear-code-changes';
export const CONFIRM_CLEAR_CODE_CHANGES = 'confirm-clear-code-changes';
export const CANCEL_CLEAR_CODE_CHANGES = 'cancel-clear-code-changes';

export enum TabBarItemsIndex {
  APP_CHAT = 0,
  APP_TEST = 1,
  APP_CODE = 2,
  APP_ENV_SECRETS = 3,
  APP_VSCODE = 4,
}

// max prompt limit
export const MAX_PROMPT_SIZE = 20000;
