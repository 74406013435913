import { FileInfo } from '../../api/generated';
import { File } from '../../../src/api/generated/models/File';
export interface AppFilesInfo {
  appName: string;
  builderSessionStateId?: string;
  oldFiles: File[];
  newFiles: File[];
  canSaveFiles?: boolean;
}

export type MessageContent = string | AppFilesInfo | File[] | FileInfo[] | FileInfo[];

export enum MessageType {
  SIGNAL = 'signal',
  APP_INFO_FILES = 'app_info_files',
  COMMAND = 'command',
}

export enum MessageSource {
  LAZY = 'lazy',
  VSCODE = 'vscode',
  SERVER = 'server',
}

export enum VSCodeConnectionStatus {
  CONNECTING = 'connecting',
  OPEN = 'open',
  CLOSED = 'closed',
  TIMEOUT = 'timeout',
}

interface VSCodeCommandParams {
  archiveName?: string;
}

export interface Message {
  source: MessageSource;
  type: MessageType;
  content: MessageContent;
  command?: string;
  commandParams?: VSCodeCommandParams;
}
