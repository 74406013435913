import { sendEventToBackend, FrontendEvents } from '../api/StatsApi';
import { useAuthStore } from '../store/auth';
import { useOrganizationStore } from '../store/organization';

interface EventEmitterHook {
  emitEvent: (eventType: FrontendEvents, appId?: string, instanceId?: string) => void;
  eventTypes: typeof FrontendEvents;
}

export const useEventEmitter = (): EventEmitterHook => {
  const { userDetails } = useAuthStore();
  const { activeOrganization } = useOrganizationStore();

  const emitEvent = (eventType: FrontendEvents, appId?: string, instanceId?: string) => {
    sendEventToBackend({
      eventType,
      appId: appId || 'unknown-app-id',
      userId: userDetails?.uid || 'unknown-user-id',
      userEmail: userDetails?.email,
      orgId: activeOrganization?.id,
      instanceId,
    });
  };

  return { emitEvent, eventTypes: FrontendEvents };
};
