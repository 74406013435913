import { MAX_PROMPT_SIZE } from '../constants';
import {
  BuilderSessionUpdateRequest,
  streamingAppSessionUpdate,
  updateAppSession,
} from '../api/BuilderApi';
import {
  AppLogic,
  BuilderMessageOrigin,
  ContentBuilderMessage,
  File,
  LazyApp,
  StarterPrompt,
} from '../api/generated/index';
import { useChatStore } from '../store/chat';
import { PROMPT_SUGGEST_3_SMALL_FEATURES } from './constants';
import { useAuthStore } from '../store/auth';
import { updateUserPermissions } from '../utils/updateUserPermissions';

interface UserPromptParams {
  prompt: string;
  isLlmIndependentRequest: boolean;
  origin: BuilderMessageOrigin;
  updatedFiles?: File[];
  manualAppLogic?: AppLogic;
}

function handleFilesUpdate(updatedFiles: File[] | undefined, apiMessage: ContentBuilderMessage) {
  if (updatedFiles) {
    apiMessage.files = updatedFiles;
  }
}

const getFirebaseMailAndUpdateUserPermissions = () => {
  const email = useAuthStore.getState().firebaseUser?.email;
  if (email) {
    return updateUserPermissions(email);
  }
};

// eslint-disable-next-line max-lines-per-function
const submitUserPrompt = (
  params: UserPromptParams,
  onSuccess: () => Promise<void>,
  app: LazyApp | null
) => {
  const { prompt, isLlmIndependentRequest, origin, updatedFiles, manualAppLogic } = params;

  if (app?.id && prompt.length <= MAX_PROMPT_SIZE) {
    const apiMessage: ContentBuilderMessage = {};

    if (prompt) {
      apiMessage.text = {
        text_language: 'unknown',
        text: prompt,
      };
    }

    if (origin) {
      apiMessage.origin = origin;
    }

    handleFilesUpdate(updatedFiles, apiMessage);

    if (manualAppLogic) {
      apiMessage.app_logic = manualAppLogic;
    }

    const useStreaming = process.env.REACT_APP_FLAG_ENABLE_STREAMING_APP_SESSION_UPDATES === 'true';

    const builderSessionUpdateRequest: BuilderSessionUpdateRequest = useStreaming
      ? streamingAppSessionUpdate(app.id, apiMessage, isLlmIndependentRequest)
      : updateAppSession(app.id, apiMessage, isLlmIndependentRequest);

    return useChatStore
      .getState()
      .submitUserPrompt(prompt, builderSessionUpdateRequest)
      .then((isSubmittedSuccessfully) => {
        return getFirebaseMailAndUpdateUserPermissions()?.then(() => {
          return isSubmittedSuccessfully;
        });
      })
      .then((isSubmittedSuccessfully: boolean) => {
        if (isSubmittedSuccessfully) {
          (async () => await onSuccess())();
        }
        return null;
      });
  }
};

export const submitUserPromptWithoutWaiting =
  (onSuccess: () => Promise<void>, app: LazyApp | null) => (prompt: string) => {
    // eslint-disable-next-line no-void
    void submitUserPrompt(
      {
        prompt,
        isLlmIndependentRequest: false,
        origin: BuilderMessageOrigin.CHAT_INPUT,
      },
      onSuccess,
      app
    );
  };

export const submitUserPromptForSecretUpdate =
  (onSuccess: () => Promise<void>, app: LazyApp | null) => async (prompt: string) => {
    await submitUserPrompt(
      {
        prompt,
        isLlmIndependentRequest: true,
        origin: BuilderMessageOrigin.UPDATED_SECRETS,
      },
      onSuccess,
      app
    );
    await onSuccess();
  };

export const submitStarterPrompt =
  (onSuccess: () => Promise<void>, app: LazyApp | null) => (starterPrompt: StarterPrompt) => {
    // eslint-disable-next-line no-void
    void submitUserPrompt(
      {
        prompt: starterPrompt.prompt,
        isLlmIndependentRequest: false,
        origin: BuilderMessageOrigin.STARTER_PROMPT,
      },
      onSuccess,
      app
    );
  };

export const suggestSmallFeatures = (onSuccess: () => Promise<void>, app: LazyApp | null) => {
  // eslint-disable-next-line no-void
  void submitUserPrompt(
    {
      prompt: PROMPT_SUGGEST_3_SMALL_FEATURES,
      isLlmIndependentRequest: false,
      origin: BuilderMessageOrigin.SUGGEST_SMALL_FEATURES,
    } as UserPromptParams,
    onSuccess,
    app
  );
};

// Parses the CodeListing signal updates.
export const updateFileManually =
  (files: File[], onSuccess: () => Promise<void>, app: LazyApp | null) =>
  async (oldFile: File, newFile: File) => {
    const updatedFiles = files.map((file) => {
      if (file.name === oldFile.name && file.path === oldFile.path) {
        return newFile;
      }
      return file;
    });

    await submitUserPrompt(
      {
        prompt: '',
        isLlmIndependentRequest: true,
        updatedFiles,
        origin: BuilderMessageOrigin.UPDATED_FILES,
      },
      onSuccess,
      app
    );
  };

export const updateVSCodeFilesManually =
  (files: File[], onSuccess: () => Promise<void>, app: LazyApp | null) =>
  async (updatedFiles: File[]) => {
    await submitUserPrompt(
      {
        prompt: '',
        isLlmIndependentRequest: true,
        updatedFiles,
        origin: BuilderMessageOrigin.UPDATED_FILES,
      },
      onSuccess,
      app
    );
  };

export const updateAppLogicManually =
  (onSuccess: () => Promise<void>, appLogic: AppLogic | null, app: LazyApp | null) =>
  async (newName: string, newDescription: string) => {
    await submitUserPrompt(
      {
        prompt: '',
        isLlmIndependentRequest: true,
        manualAppLogic: {
          ...appLogic,
          app_name: newName,
          summary: newDescription,
        },
        origin: BuilderMessageOrigin.UPDATED_APP_LOGIC,
      },
      onSuccess,
      app
    );
  };
