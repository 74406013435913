import React, { Ref, useEffect, useState } from 'react';
import { useAuthStore } from '../store/auth';
import { useOrganizationStore } from '../store/organization';
import classNames from 'classnames';
import LogoutCircleIcon from 'remixicon-react/LogoutCircleRLineIcon';
import AddFillIcon from 'remixicon-react/AddFillIcon';
import useModalVisibility from '../hooks/modalVisibility';
import TestUser from '../assets/test-user-profile.svg';
import ExpandUpDownIcon from '../assets/expand-up-down.svg';
import { isMobileDevice } from '../utils/deviceDimensions';
import HotelLineIcon from 'remixicon-react/HotelLineIcon';
import SettingsLineIcon from 'remixicon-react/SettingsLineIcon';
import GroupIcon from 'remixicon-react/GroupLineIcon';
import ActionButton from '../components/base/ActionButton';
import { OrganizationUserRoleAssignment, UserService } from '../api/generated';
import { useNavigate } from 'react-router';
import {
  TEST_ID_AUTH_NAV_DROPDOWN_BUTTON,
  TEST_ID_AUTH_NAV_CREATE_NEW_TEAM_BUTTON,
  TEST_ID_AUTH_NAV_MANAGE_TEAM_BUTTON,
  TEST_ID_AUTH_NAV_SWITCH_TEAM_BUTTON,
  TEST_ID_AUTH_NAV_TEAM_NAME_LABEL,
  LAZY_ACCOUNTS_DOMAIN,
  LAZY_USER_PROFILE_PAGE,
} from '../constants';

interface AuthNavbarProps {
  sidebar?: boolean;
}

// eslint-disable-next-line max-lines-per-function
const AuthNavbar: React.FC<AuthNavbarProps> = ({ sidebar = false }) => {
  const { userIsAuthenticated, firebaseUser: user } = useAuthStore();

  const {
    toggleCreateNewOrganizationModalState,
    toggleOrganizationManagementModalState,
    setOrganizationBeingConfigured,
    userOrganizationRoleAssignments,
    setActiveOrganization,
    activeOrganization,
  } = useOrganizationStore();
  const isMobileView = isMobileDevice();
  const { itemRef, setIsVisible, isVisible } = useModalVisibility(false);

  if (!userIsAuthenticated || !user) {
    return <></>;
  }

  const navigate = useNavigate();

  const onEditAccountHandler = () => {
    UserService.userGetUserEmailAndTimestampEncrypted()
      .then((result) => {
        window.open(
          `${LAZY_ACCOUNTS_DOMAIN}${LAZY_USER_PROFILE_PAGE}?encrypted_email=${result}`,
          '_blank'
        );
        return null;
      })
      .catch(() => {
        // eslint-disable-next-line no-empty-function
      });
  };

  const onLogout = () => {
    useAuthStore
      .getState()
      .signOut()
      .then(() => navigate('/login'))
      // There's really not that much that can be done here. This is not expected to fail,
      // and if it did, going around clearing DB datastores is not exactly adequate
      // eslint-disable-next-line @typescript-eslint/no-empty-function
      .catch((_e) => {});
  };

  // eslint-disable-next-line max-lines-per-function
  const renderPersonalAccountSelector = () => (
    <div className="flex items-center justify-between">
      <button
        onClick={() => {
          activeOrganization?.id && setActiveOrganization(null, true);
        }}
        className="flex flex-1 gap-3 cursor-pointer p-2"
        data-testid={!sidebar && TEST_ID_AUTH_NAV_SWITCH_TEAM_BUTTON}
      >
        <div className="pt-0.5">
          <img
            className="rounded-full inline-block h-6 w-6"
            referrerPolicy="no-referrer"
            src={user.photoURL ?? (TestUser as string)}
            alt=""
          />
        </div>
        <div className="flex-1 flex flex-col items-start">
          <span className="text-label-primary font-medium">{user.displayName}</span>
          <span
            className="text-label-secondary text-xs"
            data-testid={!sidebar && TEST_ID_AUTH_NAV_TEAM_NAME_LABEL}
          >
            Personal
          </span>
        </div>
      </button>

      {
        <div className="flex items-center justify-center p-2">
          <ActionButton fillState="subtle" size="small" onClick={() => onEditAccountHandler()}>
            <SettingsLineIcon size={16} className="text-system-accent rounded mr-1" />
          </ActionButton>
        </div>
      }
    </div>
  );

  // eslint-disable-next-line max-lines-per-function
  const renderOrganizationAccountSelector = (roleAssignment: OrganizationUserRoleAssignment) => (
    <div className="flex items-center justify-between">
      <button
        className="flex flex-1 gap-3 cursor-pointer p-2"
        onClick={() =>
          activeOrganization?.id !== roleAssignment.org.id &&
          setActiveOrganization(roleAssignment.org, true)
        }
        data-testid={!sidebar && TEST_ID_AUTH_NAV_SWITCH_TEAM_BUTTON}
      >
        <div className="pt-0.5">
          {roleAssignment.org.image ? (
            <img src={roleAssignment.org.image} className="w-6 h-6" />
          ) : (
            <HotelLineIcon size={24} className="bg-system-accent text-white p-1 rounded-full" />
          )}
        </div>
        <div className="flex-1 flex flex-col items-start">
          <span
            className="text-label-primary font-medium"
            data-testid={!sidebar && TEST_ID_AUTH_NAV_TEAM_NAME_LABEL}
          >
            {roleAssignment.org.name}
          </span>
          <span className="text-label-secondary text-xs">
            {(roleAssignment.org.organization_user_roles_assignment as []).length} member
            {(roleAssignment.org.organization_user_roles_assignment as []).length > 1 ? 's' : ''}
          </span>
        </div>
      </button>
      <div className="flex items-center justify-center p-2">
        <ActionButton
          fillState="subtle"
          size="small"
          onClick={(e) => {
            setIsVisible(false);
            setOrganizationBeingConfigured(roleAssignment.org);
            toggleOrganizationManagementModalState();
            e.stopPropagation();
          }}
          data-testid={!sidebar && TEST_ID_AUTH_NAV_MANAGE_TEAM_BUTTON}
        >
          <SettingsLineIcon size={16} className="text-system-accent rounded mr-1" />
        </ActionButton>
      </div>
    </div>
  );

  const [selectedOrganizationRoleAssignment, setSelectedOrganizationRoleAssignment] =
    useState<OrganizationUserRoleAssignment | null>(null);

  const setupSelectedOrganizationRoleAssignment = () => {
    if (activeOrganization?.id) {
      const selectedUserRoleAssignment = userOrganizationRoleAssignments.find(
        (roleAssignment) => roleAssignment.org.id === activeOrganization.id
      );
      setSelectedOrganizationRoleAssignment(selectedUserRoleAssignment || null);
    } else {
      setSelectedOrganizationRoleAssignment(null);
    }
  };

  useEffect(() => {
    setupSelectedOrganizationRoleAssignment();
  }, [activeOrganization?.id, userOrganizationRoleAssignments]);

  useEffect(() => {
    setupSelectedOrganizationRoleAssignment();
  }, []);

  return (
    <div
      className={classNames('flex', {
        'justify-end w-[250px]': !sidebar,
      })}
    >
      <div ref={itemRef as Ref<HTMLDivElement>}>
        <div className="flex gap-2.5 items-center">
          {!sidebar && !isMobileView && !activeOrganization?.id && (
            <ActionButton
              size="large"
              onClick={(e) => {
                setIsVisible(false);
                toggleCreateNewOrganizationModalState();
                e.stopPropagation();
              }}
              data-testid={!sidebar && TEST_ID_AUTH_NAV_CREATE_NEW_TEAM_BUTTON}
            >
              <div className="flex items-center justify-center gap-1">
                <GroupIcon size={16} className="text-white" />
                <span>Start a team</span>
              </div>
            </ActionButton>
          )}
          <div
            className="flex items-center justify-center gap-1 cursor-pointer"
            onClick={() => setIsVisible(true)}
            data-testid={!sidebar && TEST_ID_AUTH_NAV_DROPDOWN_BUTTON}
          >
            {activeOrganization?.id ? (
              activeOrganization.image ? (
                <img
                  className="rounded-full inline-block h-7 w-7"
                  referrerPolicy="no-referrer"
                  src={activeOrganization.image}
                  alt=""
                />
              ) : (
                <HotelLineIcon
                  size={28}
                  className="inline-block bg-system-accent text-white p-1 rounded-full"
                />
              )
            ) : (
              <img
                className="rounded-full inline-block h-7 w-7"
                referrerPolicy="no-referrer"
                src={user.photoURL ?? (TestUser as string)}
                alt=""
              />
            )}

            {sidebar && (
              <span>{activeOrganization?.id ? activeOrganization.name : user.displayName}</span>
            )}
            <img src={ExpandUpDownIcon as string} alt="Expand Up Down Icon" />
          </div>
        </div>

        <div
          className={classNames(
            'absolute bg-white z-[100] cursor-default',
            {
              hidden: !isVisible,
            },
            { 'left-5': sidebar },
            { 'right-5': !sidebar }
          )}
        >
          <ul className="shadow-2xl rounded w-[250px] max-h-[70vh] overflow-y-auto">
            <li className="bg-white border-b border-system-separator mb-2">
              {selectedOrganizationRoleAssignment
                ? renderOrganizationAccountSelector(selectedOrganizationRoleAssignment)
                : renderPersonalAccountSelector()}
            </li>

            {userOrganizationRoleAssignments.length > 0 && (
              <>
                <span className="text-label-secondary text-xs pl-2">Switch accounts</span>

                {selectedOrganizationRoleAssignment && <li>{renderPersonalAccountSelector()}</li>}
                {
                  // eslint-disable-next-line max-lines-per-function
                  userOrganizationRoleAssignments
                    .filter(
                      (roleAssignment) =>
                        !selectedOrganizationRoleAssignment ||
                        roleAssignment.id !== selectedOrganizationRoleAssignment?.id
                    )
                    .map((roleAssignment) => (
                      <li key={roleAssignment.id}>
                        {renderOrganizationAccountSelector(roleAssignment)}
                      </li>
                    ))
                }
              </>
            )}

            {isMobileView && (
              <li className="bg-white border-b border-system-separator">
                <button
                  onClick={(e) => {
                    setIsVisible(false);
                    toggleCreateNewOrganizationModalState();
                    e.stopPropagation();
                  }}
                  className="text-system-accent p-2 pr-16"
                  data-testid={!sidebar && TEST_ID_AUTH_NAV_CREATE_NEW_TEAM_BUTTON}
                >
                  <AddFillIcon size={18} className={'inline mr-2 align-text-bottom'} /> Create new
                  team
                </button>
              </li>
            )}

            <li
              className={classNames('bg-white', {
                'border-t border-system-separator': userOrganizationRoleAssignments.length !== 0,
              })}
            >
              <button onClick={onLogout} className="text-red-600 p-2 pr-16 ">
                <LogoutCircleIcon size={18} className={'inline mr-2 align-text-bottom'} /> Logout
              </button>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default AuthNavbar;
