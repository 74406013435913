/* eslint-disable  */
import React, { memo, useEffect, useState, useRef } from 'react';
import axios from 'axios';
import { toast } from 'react-toastify';
import { getAppSecretsStorageToken } from '../api/BuilderApi';
import { SecretTile } from './sercetManagement/SecretTile';
import { Secret } from '../types/secret';
import { Loader } from './base/Loader';
import { ApproveModal } from '../components/base/ApproveModal';
import ActionButton from '../components/base/ActionButton';
import * as dataTestidConstants from '../constants';
import classNames from 'classnames';

const API_BASE_URL = process.env.REACT_APP_SECRETS_STORAGE_URL as string;

export function useSecretsStorage(token: string) {
  const api = axios.create({
    baseURL: `${API_BASE_URL}`,
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
    timeout: 10000,
  });

  const listSecrets = async () => {
    const response = await api.get('/secrets');
    return response.data as ListSecretsResponse;
  };

  const storeSecret = async (secrets) => {
    const response = await api.post('/secrets', secrets);
    return response.data as StoreSecretResponse;
  };

  const removeSecret = async (name: string) => {
    const response = await api.delete(`/secret/${name}`);
    return response.data as DeleteSecretResponse;
  };

  return { listSecrets, storeSecret, removeSecret };
}

interface ListSecretsResponse {
  secrets: Secret[];
}

interface StoreSecretResponse {
  message: string;
}

interface DeleteSecretResponse {
  message: string;
}

interface SecretsManagerProps {
  appId: string | null;
  submitUserPromptForSecretUpdate: (string) => Promise<void>;
}

interface SecretInEditModeRef {
  hasUnsavedChanges: () => boolean;
  discardUnsavedChanges: () => void;
}

// eslint-disable-next-line max-lines-per-function
const SecretsManager = memo(({ appId, submitUserPromptForSecretUpdate }: SecretsManagerProps) => {
  const [isLoading, setLoading] = useState(true);
  const [showNewSecretTile, setShowNewSecretTile] = useState(false);
  const [editModeEnabledFor, setToEditMode] = useState<null | string>(null);
  const [secrets, setSecrets] = useState<Secret[]>([]);
  const [token, setToken] = useState<string>('');

  useEffect(() => {
    if (appId) {
      getAppSecretsStorageToken(appId)
        .then((token) => {
          setToken(token);
          return null;
        })
        .catch(() => toast.error('Access denied to secrets. Please try again later.'))
        .finally(() => setLoading(false));
    }
  }, [appId]);

  const { listSecrets, storeSecret, removeSecret } = useSecretsStorage(token);

  const fetchSecrets = async () => {
    try {
      const response = await listSecrets();
      setSecrets(response.secrets);
    } catch (error) {
      toast.error('Error fetching secrets');
      // eslint-disable-next-line no-console
      console.error(`Error fetching secrets: ${error as string}`);
    } finally {
      setLoading(false);
    }
  };

  const createSecret = async (secret: Secret) => {
    try {
      setLoading(true);
      await storeSecret([secret]);
      setSecrets([...secrets, secret]);
      await submitUserPromptForSecretUpdate(`Builder added environment secret: ${secret.name}`);
    } catch (error) {
      toast.error('Error creating secret');
      // eslint-disable-next-line no-console
      console.error(`Error creating secret: ${error as string}`);
    } finally {
      setLoading(false);
      setShowNewSecretTile(false);
    }
  };

  const updateSecret = async (updatedSecret: Secret) => {
    try {
      setLoading(true);
      await storeSecret([updatedSecret]);
      const updatedSecrets = secrets.map((secret) =>
        secret.name === updatedSecret.name ? updatedSecret : secret
      );
      setSecrets(updatedSecrets);
      await submitUserPromptForSecretUpdate(
        `Builder updated environment secret: ${updatedSecret.name}`
      );
    } catch (error) {
      toast.error(`Error updating secret: ${error as string}`);
    } finally {
      setLoading(false);
    }
  };

  const deleteSecret = async (name: string) => {
    try {
      setLoading(true);
      await removeSecret(name);
      const updatedSecrets = secrets.filter((secret) => secret.name !== name);
      setSecrets(updatedSecrets);
      await submitUserPromptForSecretUpdate(`Builder deleted environment secret: ${name}`);
    } catch (error) {
      toast.error(`Error deleting secret: ${error as string}`);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (token) {
      // eslint-disable-next-line @typescript-eslint/no-floating-promises
      fetchSecrets();
    }
  }, [token]);

  const secretInEditModeRef = useRef<SecretInEditModeRef>(null);

  const renderSecretUnsavedChangesConfirmationModal = () => (
    <ApproveModal
      message={
        <span className="whitespace-pre-line">
          Secret {editModeEnabledFor} has unsaved changes.
          <br />
          Are you sure you want to proceed without saving?
        </span>
      }
      approveButton={{
        text: 'Yes',
        className: 'bg-system-danger text-white',
      }}
      rejectButton={{
        text: 'No',
      }}
      onApprove={() => {
        secretInEditModeRef.current?.discardUnsavedChanges();
        setToEditMode(showApprovalModalBeforeEditingSecret);
        setShowApprovalModalBeforeEditingSecret(null);
      }}
      onReject={() => setShowApprovalModalBeforeEditingSecret(null)}
    />
  );

  const [showApprovalModalBeforeEditingSecret, setShowApprovalModalBeforeEditingSecret] = useState<
    null | string
  >(null);

  return (
    <div className="flex items-start justify-start w-full relative">
      <div
        className={classNames(
          'px-4 lg:px-4 container max-w-[720px]',
          'pt-12 pb-12 lg:pb-0 overflow-y-auto'
        )}
      >
        {isLoading && (
          <Loader fullScreen={false} dataTestId={dataTestidConstants.ENV_SECRETS_LOADING_SPINNER} />
        )}
        {showApprovalModalBeforeEditingSecret && renderSecretUnsavedChangesConfirmationModal()}
        <div className="grid gap-6">
          <h1 className="text-3xl font-semibold">Environment Secrets Management</h1>
          <h3 className="font-light text-400 text-subtitle-custom-gray text-[16px]">
            Environment secrets will be made available as environment variables to the app
          </h3>
          {secrets.map((secret) => (
            <SecretTile
              ref={editModeEnabledFor === secret.name ? secretInEditModeRef : null}
              key={secret.name}
              secret={secret}
              onCancel={() => {
                setToEditMode(null);
              }}
              onSave={(secret) => {
                setToEditMode(null);
                updateSecret(secret);
              }}
              onEdit={() => {
                if (
                  secretInEditModeRef.current &&
                  secretInEditModeRef.current.hasUnsavedChanges()
                ) {
                  setShowApprovalModalBeforeEditingSecret(secret.name);
                } else {
                  setToEditMode(secret.name);
                }
              }}
              onRemove={() => deleteSecret(secret.name)}
              isEditMode={editModeEnabledFor === secret.name}
            />
          ))}
          {showNewSecretTile ? (
            <SecretTile
              secret={undefined}
              isEditMode
              isNew
              onSave={createSecret}
              onCancel={() => {
                setShowNewSecretTile(false);
              }}
            />
          ) : (
            <div>
              <ActionButton
                onClick={() => setShowNewSecretTile(true)}
                data-testid={dataTestidConstants.ENV_SECRETS_ADD_NEW_SECRET_BUTTON}
              >
                Add new secret
              </ActionButton>
            </div>
          )}
        </div>
      </div>
    </div>
  );
});

export default SecretsManager;
