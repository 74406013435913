import { create } from 'zustand';
import {
  Organization,
  OrganizationUserRoleAssignment,
  OrganizationUpdate,
  OrganizationWithInvitesAndRoles,
  Role,
} from '../api/generated';
import * as OrganizationApi from '../api/OrganizationApi';
import { toast } from 'react-toastify';

const ACTIVE_ORGANIZATION_KEY = 'ACTIVE_ORGANIZATION_ID';

export enum RoleNames {
  ADMIN = 'Admin',
  BUILDER = 'Builder',
}

interface OrganizationStoreState {
  isCreateNewOrganizationModalOpen: boolean;
  isOrganizationManagementModalOpen: boolean;
  organizationBeingConfigured: OrganizationWithInvitesAndRoles | null;
  activeOrganization: Organization | null;
  userOrganizationRoleAssignments: OrganizationUserRoleAssignment[];
  roles: Role[];
  adminUserRole: Role | null;
  builderUserRole: Role | null;
  isLoading: boolean;
  error: Error | null;

  loadRoles: () => Promise<void>;
  loadUserOrganizationRoleAssignments: () => Promise<void>;
  loadOrganization: (string) => Promise<void>;
  setOrganizationBeingConfigured: (organization: Organization) => void;
  setActiveOrganization: (organization?: Organization | null, reload?: boolean) => void;
  createOrganization: (organizationName: string) => Promise<Organization | null>;
  updateOrganization: (organizationUpdate: OrganizationUpdate) => Promise<void>;
  inviteUserToOrganization: (userEmail: string, roleId: string) => Promise<void>;
  removeUserInvite: (userId: string) => Promise<void>;
  resendUserInvite: (inviteId: string) => Promise<void>;
  acceptUserInvite: (orgId: string, inviteId: string) => Promise<void>;
  changeUserRole: (userId: string, userRoleAssignmentId: string) => Promise<void>;
  removeUserRole: (userRoleAssignmentId: string) => Promise<void>;
  toggleCreateNewOrganizationModalState: () => void;
  toggleOrganizationManagementModalState: () => void;
  setError: (message: string, error: Error) => void;
  setIsLoading: (isLoading: boolean) => void;
}

// eslint-disable-next-line max-lines-per-function
export const useOrganizationStore = create<OrganizationStoreState>((set, get) => ({
  isCreateNewOrganizationModalOpen: false,
  isOrganizationManagementModalOpen: false,
  organizationBeingConfigured: null,
  error: null,
  activeOrganization: localStorage.getItem(ACTIVE_ORGANIZATION_KEY)
    ? (JSON.parse(localStorage.getItem(ACTIVE_ORGANIZATION_KEY) as string) as Organization)
    : null,
  userOrganizationRoleAssignments: [],
  roles: [],
  adminUserRole: null,
  builderUserRole: null,
  isLoading: false,

  setIsLoading: (isLoading: boolean) => {
    set({ isLoading });
  },

  loadUserOrganizationRoleAssignments: async () => {
    try {
      set({ isLoading: true });

      const organizationUserRoleAssignments = await OrganizationApi.getUserRoleAssignments();
      if (get().activeOrganization) {
        if (
          !organizationUserRoleAssignments.find(
            (roleAssignment) => roleAssignment.org.id === get().activeOrganization?.id
          )
        ) {
          set({ activeOrganization: null });
        }
      }
      set({
        userOrganizationRoleAssignments: organizationUserRoleAssignments,
        isLoading: false,
      });
    } catch (error) {
      set({ isLoading: false });
      get().setError('Error loading user teams', error as Error);
    }
  },

  loadOrganization: async (orgId: string) => {
    try {
      set({ isLoading: true });

      const organization = await OrganizationApi.getOrganization(orgId);

      set({
        isLoading: false,
        organizationBeingConfigured: organization,
        activeOrganization: organization,
      });
    } catch (error) {
      set({ isLoading: false });
      get().setError('Error loading team', error as Error);
      get().setActiveOrganization(null);
    }
  },
  loadRoles: async () => {
    try {
      set({ isLoading: true });

      const roles = await OrganizationApi.getAllRoles();
      set({
        isLoading: false,
        roles,
        adminUserRole: roles.find((role) => role.name === RoleNames.ADMIN),
        builderUserRole: roles.find((role) => role.name === RoleNames.BUILDER),
      });
    } catch (error) {
      set({ isLoading: false });
      get().setError('Error loading roles', error as Error);
    }
  },

  setOrganizationBeingConfigured: (organization: Organization) => {
    set({ organizationBeingConfigured: organization });
  },

  setError: (message: string, error: Error) => {
    set({ error, isLoading: false });
    toast.error(message);
  },

  setActiveOrganization: (organization?: Organization, reload = false) => {
    if (organization) {
      localStorage.setItem(ACTIVE_ORGANIZATION_KEY, JSON.stringify(organization));
    } else {
      localStorage.removeItem(ACTIVE_ORGANIZATION_KEY);
    }
    set({ activeOrganization: organization });
    if (reload) {
      window.location.assign('/?sidebar_open=true');
    }
  },

  createOrganization: async (organizationName): Promise<Organization | null> => {
    try {
      set({ isLoading: true });
      const organization = await OrganizationApi.createOrganization(organizationName);
      await get().loadOrganization(organization.id);
      await get().loadUserOrganizationRoleAssignments();
      return organization;
    } catch (error) {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
      const apiErrorBody = (error?.body?.detail as string) || '';
      get().setError(`Error creating team: ${apiErrorBody}`, error as Error);
    }
    return null;
  },

  updateOrganization: async (organizationUpdate: OrganizationUpdate) => {
    try {
      set({ isLoading: true });
      const organizationBeingConfigured = get().organizationBeingConfigured;
      if (organizationBeingConfigured) {
        await OrganizationApi.updateOrganization(
          organizationBeingConfigured.id,
          organizationUpdate
        );

        await get().loadOrganization(organizationBeingConfigured.id);
        await get().loadUserOrganizationRoleAssignments();
      }
    } catch (error) {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
      const apiErrorBody = (error?.body?.detail as string) || '';
      get().setError(`Error updating team: ${apiErrorBody}`, error as Error);
    }
  },
  inviteUserToOrganization: async (userEmail, roleId) => {
    set({ isLoading: true });
    try {
      const organizationBeingConfigured = get().organizationBeingConfigured;
      if (organizationBeingConfigured) {
        await OrganizationApi.sendInvite(organizationBeingConfigured.id, userEmail, roleId);
      }
    } catch (error) {
      get().setError('Error inviting user to team', error as Error);
    }
    set({ isLoading: false });
  },

  removeUserInvite: async (inviteId: string) => {
    set({ isLoading: true });
    try {
      const organizationBeingConfigured = get().organizationBeingConfigured;
      if (organizationBeingConfigured) {
        await OrganizationApi.deleteInvite(organizationBeingConfigured.id, inviteId);

        await get().loadOrganization(organizationBeingConfigured.id);
      }
    } catch (error) {
      get().setError('Error removing user invitation', error as Error);
    }
    set({ isLoading: false });
  },

  resendUserInvite: async (inviteId: string) => {
    set({ isLoading: true });
    try {
      const organizationBeingConfigured = get().organizationBeingConfigured;
      if (organizationBeingConfigured) {
        await OrganizationApi.resendInvite(organizationBeingConfigured.id, inviteId);

        await get().loadOrganization(organizationBeingConfigured.id);
      }
    } catch (error) {
      get().setError('Error resending user invitation', error as Error);
    }
    set({ isLoading: false });
  },

  acceptUserInvite: async (orgId: string, inviteId: string) => {
    try {
      const invite = await OrganizationApi.acceptInvite(orgId, inviteId);
      toast.success('Invitation accepted successfully.');
      get().setActiveOrganization(invite.org);
    } catch (error) {
      get().setError('Error accepting team invitation', error as Error);
    }
  },

  changeUserRole: async (userRoleId: string, userRoleAssignmentId: string) => {
    set({ isLoading: true });
    try {
      const organizationBeingConfigured = get().organizationBeingConfigured;
      if (organizationBeingConfigured) {
        await OrganizationApi.changeUserRole(
          organizationBeingConfigured.id,
          userRoleId,
          userRoleAssignmentId
        );

        await get().loadOrganization(organizationBeingConfigured.id);
        await get().loadUserOrganizationRoleAssignments();
      }
    } catch (error) {
      get().setError('Error changing user role', error as Error);
    }
  },

  removeUserRole: async (userRoleAssignmentId: string) => {
    set({ isLoading: true });
    try {
      const organizationBeingConfigured = get().organizationBeingConfigured;
      if (organizationBeingConfigured) {
        await OrganizationApi.deleteUserRole(organizationBeingConfigured.id, userRoleAssignmentId);

        await get().loadOrganization(organizationBeingConfigured.id);
        await get().loadUserOrganizationRoleAssignments();
      }
    } catch (error) {
      get().setError('Error removing user role', error as Error);
    }
  },

  toggleCreateNewOrganizationModalState: () => {
    set((state) => ({ isCreateNewOrganizationModalOpen: !state.isCreateNewOrganizationModalOpen }));
  },

  toggleOrganizationManagementModalState: () => {
    set((state) => ({
      isOrganizationManagementModalOpen: !state.isOrganizationManagementModalOpen,
    }));
  },
}));
