import {
  AppService,
  BuilderSessionStateService,
  BuilderSessionState,
  ContentBuilderMessage,
  InstanceService,
  LazyApp,
  LazyAppMainInstanceInfo,
  LazyAppCreate,
  LazyAppUpdate,
  LazyAppVersion,
  VersionService,
  StoreAuthenticationKeyParams,
  StoreCustomAuthenticationParams,
  BuilderSessionStateRequestStatus,
  CancelablePromise,
} from './generated';
import { setupHeaders } from './index';
import { getLastPublishedVersionFromVersions } from '../utils';
import { AxiosError } from 'axios';
import { StreamingAppService } from './sse/StreamingAppService';

setupHeaders && setupHeaders();

export async function getApp(appId: string): Promise<LazyApp> {
  const requestPromise: CancelablePromise<LazyApp> = AppService.appGetApp(appId);
  requestPromise.catch((response: AxiosError) => {
    if (response.status === 404 || response.status === 401 || response.status === 403) {
      window.location.href = '/404';
    } else {
      throw response;
    }
  });

  return requestPromise;
}

export async function getAppInstanceInfo(
  appId: string,
  isTestVersion: boolean
): Promise<LazyAppMainInstanceInfo> {
  return AppService.appGetMainInstanceInfo(appId, isTestVersion);
}

export function generateTemplate(appId: string) {
  return AppService.appGenerateTemplate(appId);
}

export function getCustomDomainsForApp(appId: string) {
  return AppService.appGetAllCustomDomainsForApp(appId);
}

export function getVerifiedCustomDomainForApp(appId: string) {
  // eslint-disable-next-line @typescript-eslint/no-unsafe-call, @typescript-eslint/no-unsafe-return
  return AppService.appGetVerifiedCustomDomainByAppIdExternal(appId);
}

export function checkVerifiedCustomDomainAccessibility(appId: string) {
  // eslint-disable-next-line @typescript-eslint/no-unsafe-return, @typescript-eslint/no-unsafe-call
  return AppService.appCheckVerifiedCustomDomainAccessibility(appId);
}

export function deleteCustomDomainForApp(appId: string, domainId: string) {
  return AppService.appDeleteAppCustomDomain(appId, domainId);
}

export function createCustomDomainForApp(appId: string, domainName: string) {
  return AppService.appCreateAppCustomDomain(appId, { name: domainName });
}

export function markCustomDomainAsVerified(appId: string, domainId: string) {
  return AppService.appVerifyAppCustomDomain(appId, domainId);
}

export function getAppSecretsStorageToken(appId: string) {
  return AppService.appGetAppSecretsStorageJwtToken(appId);
}

export function updateApp(appId: string, update: LazyAppUpdate) {
  return AppService.appUpdateApp(appId, update);
}

export async function getAppSession(
  appId: string,
  numMessages: number
): Promise<BuilderSessionState> {
  const requestPromise: CancelablePromise<BuilderSessionState> = AppService.appGetAppSession(
    appId,
    numMessages
  );
  requestPromise.catch((response: AxiosError) => {
    if (response.status === 401 || response.status === 404 || response.status === 403) {
      window.location.href = '/404';
    } else {
      throw response;
    }
  });
  return requestPromise;
}

export class BuilderSessionStateUpdateEvent<BuilderSessionState> extends Event {
  static readonly type: string = 'BuilderSessionStateUpdateEvent';
  public readonly detail: BuilderSessionState;

  constructor(detail: BuilderSessionState) {
    super(BuilderSessionStateUpdateEvent.type);
    this.detail = detail;
  }
}

export type BuilderSessionUpdateRequest = {
  request: CancelablePromise<BuilderSessionState>;
  requestStreamEventTarget?: EventTarget;
};

export function updateAppSession(
  appId: string,
  message: ContentBuilderMessage,
  isLlmIndependentRequest: boolean
): BuilderSessionUpdateRequest {
  const builderSessionUpdateRequest = AppService.appUpdateAppSession(
    appId,
    message,
    isLlmIndependentRequest
  );
  return {
    request: builderSessionUpdateRequest,
  };
}

export function streamingAppSessionUpdate(
  appId: string,
  message: ContentBuilderMessage,
  isLlmIndependentRequest: boolean
): BuilderSessionUpdateRequest {
  const requestStreamEventTarget = new EventTarget();
  const onMessage = <BuilderSessionState>(data: BuilderSessionState) => {
    requestStreamEventTarget.dispatchEvent(new BuilderSessionStateUpdateEvent(data));
  };

  const request = StreamingAppService.appUpdateAppSession(
    appId,
    message,
    isLlmIndependentRequest,
    onMessage
  );
  return {
    request,
    requestStreamEventTarget,
  };
}

export function updateBuilderSessionStateStatus(
  builderSessionStateId: string,
  status: BuilderSessionStateRequestStatus
) {
  return BuilderSessionStateService.builderSessionStateUpdateBuilderSessionState(
    builderSessionStateId,
    status
  );
}

export function getBuilderSessionPreviousMessages(
  appId: string,
  beforeDatetime: string,
  numMessages: number
) {
  return AppService.appGetAppBuilderMessages(appId, beforeDatetime, numMessages);
}

export function createApp(app: LazyAppCreate) {
  return AppService.appCreateApp(app);
}

export function publishAppAsTemplate(
  appId: string,
  name: string,
  description: string,
  videoUrl: string
): Promise<LazyApp> {
  return AppService.appPublishTemplate(appId, name, description, videoUrl);
}

export function getMyApps(orgId?: string) {
  return AppService.appGetAllApps(orgId);
}

export function revertApp(appId: string, id: string) {
  return AppService.appRevertToPreviousVersion(appId, id);
}

export function createAppVersion(
  app: LazyApp,
  published = false,
  builderSessionStateId?: string
): Promise<LazyAppVersion> {
  return VersionService.versionCreateVersion(app.id, published, builderSessionStateId);
}

export function getAllVersions(appId: string, published: boolean): Promise<LazyAppVersion[]> {
  return VersionService.versionGetAllVersions(appId, published);
}

export async function getLatestPublishedVersion(appId: string): Promise<LazyAppVersion | null> {
  const versions = await getAllVersions(appId, true);
  return getLastPublishedVersionFromVersions(versions);
}

export function storeApiAuthenticationKey(
  versionId: string,
  keyParams: StoreAuthenticationKeyParams
) {
  return InstanceService.instanceStoreAuthenticationKey(versionId, keyParams);
}

export function storeCustomAuthentication(
  versionId: string,
  params: StoreCustomAuthenticationParams
) {
  return InstanceService.instanceStoreAuthenticationFields(versionId, params);
}
