/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 * An enumeration.
 */
export enum Persona {
    USER = 'user',
    BUILDER = 'builder',
    DEVELOPER = 'developer',
    PRODUCT_MANAGER = 'product manager',
    SYSTEM = 'system',
    APP = 'app',
    OS = 'OS',
    ARCHITECT = 'Architect',
    APP_S_DEPLOYMENT_ENVIRONMENT = 'App’s Deployment Environment',
    CHAT_SESSION = 'Chat Session',
}
