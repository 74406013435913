import React from 'react';
import FlashlightIcon from 'remixicon-react/FlashlightFillIcon';
import Button from '../components/base/Button';
import { useUserAnalyticsStore } from '../store/userAnalytics';
import { LAZY_ACCOUNTS_DOMAIN, LAZY_PLAN_PAGE, TEST_UPGRADE_TO_PRO_BUTTON } from '../constants';
import { UserService } from '../api/generated';

export const upgradeToProButtonHandler = () => {
  const pricingUrl = useUserAnalyticsStore
    .getState()
    .addUtmsToUrl(`${LAZY_ACCOUNTS_DOMAIN}${LAZY_PLAN_PAGE}`);
  const secureEmailDecryptionApp = process.env.REACT_APP_LAZY_SECURE_EMAIL_DECRYPTION_APP || '';
  if (process.env.REACT_APP_USE_SECURE_EMAIL_DECRYPTION_APP === 'true') {
    const newTab = window.open('', '_blank');
    // eslint-disable-next-line max-len
    // eslint-disable-next-line @typescript-eslint/no-floating-promises, promise/catch-or-return
    UserService.userGetUserEmailAndTimestampEncrypted().then((result) => {
      // eslint-disable-next-line promise/always-return
      if (newTab) {
        newTab.location.href = `${secureEmailDecryptionApp}?encrypted_email=${result}`;
      }
    });
  } else {
    window.open(pricingUrl, '_blank');
  }
};

interface UpgradeToProButtonProps {
  customButtonText?: string;
}

export const UpgradeToProButton = (props: UpgradeToProButtonProps) => {
  return (
    <Button
      dataTestid={TEST_UPGRADE_TO_PRO_BUTTON}
      className="bg-purple-500 text-white hover:bg-purple-500/80 gap-1"
      onClick={upgradeToProButtonHandler}
      iconProps={{ icon: FlashlightIcon, iconSize: 16 }}
    >
      {props.customButtonText || 'Upgrade to Pro'}
    </Button>
  );
};
