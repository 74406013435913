/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { LazyAppInstance } from '../models/LazyAppInstance';
import type { LazyAppInstanceAddress } from '../models/LazyAppInstanceAddress';
import type { LazyAppInstanceDeployResponse } from '../models/LazyAppInstanceDeployResponse';
import type { LazyAppInstanceMinimal } from '../models/LazyAppInstanceMinimal';
import type { LazyAppInstanceState } from '../models/LazyAppInstanceState';
import type { LazyAppPubSubMessage } from '../models/LazyAppPubSubMessage';
import type { LazyAppTestInstanceTTLInfo } from '../models/LazyAppTestInstanceTTLInfo';
import type { PastTTYMessagesResponse } from '../models/PastTTYMessagesResponse';
import type { StoreAuthenticationKeyParams } from '../models/StoreAuthenticationKeyParams';
import type { StoreCustomAuthenticationParams } from '../models/StoreCustomAuthenticationParams';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class InstanceService {

    /**
     * Creates or returns an existing instance for a user to run for an app
     * Creates or returns an existing instance for a user to run for an app
     * @param appId
     * @param testing
     * @param start
     * @returns LazyAppInstanceMinimal Successful Response
     * @throws ApiError
     */
    public static instanceGetOrCreateAppInstance(
        appId: string,
        testing: boolean = false,
        start: boolean = false,
    ): CancelablePromise<LazyAppInstanceMinimal> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v1/instance/create/{app_id}',
            path: {
                'app_id': appId,
            },
            query: {
                'testing': testing,
                'start': start,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Returns the states of all instances
     * Return the states of all instances, depending on filters
     * @param running
     * @returns LazyAppInstanceState Successful Response
     * @throws ApiError
     */
    public static instanceGetInstancesStates(
        running: boolean = true,
    ): CancelablePromise<Array<LazyAppInstanceState>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/instance/state',
            query: {
                'running': running,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Returns an instance of an application for a user
     * Returns the information regarding an application instance for a user
     * @param instanceId
     * @returns LazyAppInstanceMinimal Successful Response
     * @throws ApiError
     */
    public static instanceGetInstance(
        instanceId: string,
    ): CancelablePromise<LazyAppInstanceMinimal> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/instance/{instance_id}',
            path: {
                'instance_id': instanceId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Returns the address and port an instance is running on
     * Returns the information regarding an application instance for a user
     * @param instanceId
     * @returns LazyAppInstanceAddress Successful Response
     * @throws ApiError
     */
    public static instanceEpGetInstanceAddress(
        instanceId: string,
    ): CancelablePromise<LazyAppInstanceAddress> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/instance/{instance_id}/address',
            path: {
                'instance_id': instanceId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Starts this instance of the application
     * Starts this instance of the application. If the instance is already running, does nothing.
     * @param instanceId
     * @param builderSessionStateId
     * @returns LazyAppInstanceDeployResponse Successful Response
     * @throws ApiError
     */
    public static instanceStartInstance(
        instanceId: string,
        builderSessionStateId?: string,
    ): CancelablePromise<LazyAppInstanceDeployResponse> {
        return __request(OpenAPI, {
            method: 'PATCH',
            url: '/api/v1/instance/{instance_id}/start',
            path: {
                'instance_id': instanceId,
            },
            query: {
                'builder_session_state_id': builderSessionStateId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Stops this instance of the application
     * Stops this instance of the application. If the instance is not running, does nothing.
     * @param instanceId
     * @returns any Successful Response
     * @throws ApiError
     */
    public static instanceStopInstance(
        instanceId: string,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'PATCH',
            url: '/api/v1/instance/{instance_id}/stop',
            path: {
                'instance_id': instanceId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Returns the authentication info for a provider
     * Returns the authentication info for a specific provider from the instance
     * @param instanceId
     * @param providerName
     * @returns string Successful Response
     * @throws ApiError
     */
    public static instanceGetAuthenticationInfo(
        instanceId: string,
        providerName: string,
    ): CancelablePromise<string> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/instance/{instance_id}/authentication/{provider_name}',
            path: {
                'instance_id': instanceId,
                'provider_name': providerName,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Returns whether we already have authentication info for the given provider
     * This entry point allows the frontend to check if authentication is already provided
     * @param instanceId
     * @param providerName
     * @returns any Successful Response
     * @throws ApiError
     */
    public static instanceIsAuthenticatedForProvider(
        instanceId: string,
        providerName: string,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/instance/{instance_id}/is_authenticated/{provider_name}',
            path: {
                'instance_id': instanceId,
                'provider_name': providerName,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Stores authentication key for the provider
     * This entry point is used store API authentication key for the API provider.
     * @param instanceId
     * @param requestBody
     * @returns LazyAppInstance Successful Response
     * @throws ApiError
     */
    public static instanceStoreAuthenticationKey(
        instanceId: string,
        requestBody: StoreAuthenticationKeyParams,
    ): CancelablePromise<LazyAppInstance> {
        return __request(OpenAPI, {
            method: 'PATCH',
            url: '/api/v1/instance/{instance_id}/authentication_key',
            path: {
                'instance_id': instanceId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Checks whether the user is authorized to inspect data about the instance
     * This entry point is used to check user permissions on seeing data from instance.
     * @param instanceId
     * @returns any Successful Response
     * @throws ApiError
     */
    public static instanceIsAuthorizedToSeeInstance(
        instanceId: string,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/instance/{instance_id}/is_authorized',
            path: {
                'instance_id': instanceId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Get Instance Tty History
     * @param instanceId
     * @returns PastTTYMessagesResponse Successful Response
     * @throws ApiError
     */
    public static instanceGetInstanceTtyHistory(
        instanceId: string,
    ): CancelablePromise<PastTTYMessagesResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/instance/{instance_id}/tty_history',
            path: {
                'instance_id': instanceId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Stores custom authentication fields for the provider
     * This entry point is used store Custom authentication fields for the API provider.
     * @param instanceId
     * @param requestBody
     * @returns LazyAppInstance Successful Response
     * @throws ApiError
     */
    public static instanceStoreAuthenticationFields(
        instanceId: string,
        requestBody: StoreCustomAuthenticationParams,
    ): CancelablePromise<LazyAppInstance> {
        return __request(OpenAPI, {
            method: 'PATCH',
            url: '/api/v1/instance/{instance_id}/authentication_fields',
            path: {
                'instance_id': instanceId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Push Control Message To Tty Stream
     * @param instanceId
     * @param requestBody
     * @returns any Successful Response
     * @throws ApiError
     */
    public static instancePushControlMessageToTtyStream(
        instanceId: string,
        requestBody: LazyAppPubSubMessage,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v1/instance/control_message/{instance_id}',
            path: {
                'instance_id': instanceId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Console Log Error Reporting
     * @param encodedToken
     * @returns any Successful Response
     * @throws ApiError
     */
    public static instanceConsoleLogErrorReporting(
        encodedToken: string,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v1/instance/console_log_error_reporting/{encoded_token}',
            path: {
                'encoded_token': encodedToken,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Returns instance TTL info
     * Return the test instance TTL info
     * @param instanceId
     * @returns LazyAppTestInstanceTTLInfo Successful Response
     * @throws ApiError
     */
    public static instanceGetInstanceTtlInfo(
        instanceId: string,
    ): CancelablePromise<LazyAppTestInstanceTTLInfo> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/instance/{instance_id}/ttl_info',
            path: {
                'instance_id': instanceId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Returns an encrypted token that can be used to fetch resources stored on GCS using authentication token
     * Returns an encrypted token to be used to fetch resources
     * @param filePath
     * @returns string Successful Response
     * @throws ApiError
     */
    public static instanceGetUploadedResourceAuthenticationToken(
        filePath: string,
    ): CancelablePromise<string> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/instance/uploaded_resource_token/{file_path}',
            path: {
                'file_path': filePath,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

}
