import React, { useEffect, useState, KeyboardEvent, ChangeEvent } from 'react';
import { useAuthStore } from '../../../store/auth';
import { useSearchParams } from 'react-router-dom';
import classNames from 'classnames';
import emailValidator from 'email-validator';
import { UserService } from '../../../api/generated/services/UserService';

const TERMS_AND_CONDITIONS_URL =
  'https://docs.google.com/document/d/1XdA3p64216Kmo-c3mt2aTsoV22PvyeRJK2xghIjavXs/edit#heading=h.9o9t07h065mh';

const PRIVACY_POLICY_URL =
  'https://docs.google.com/document/d/1YSp-vOt9TXHRGQhtCHLVt8Q7qADzlxGRhN1o2jacS-4/edit#heading=h.lg81b9un2kop';

const PASSWORDLESS_SIGN_IN_LOCAL_STORAGE_EMAIL_KEY = 'passwordlessSignInEmailAddress';
const PASSWORDLESS_QUERY_PARAM = 'passwordless';

// eslint-disable-next-line max-lines-per-function
export const BuilderSignInMessage = () => {
  const [searchParams, _setSearchParams] = useSearchParams();

  const [waitingForSocialSignIn, setWaitingForSocialSignIn] = useState(false);
  const [waitingForEmailSignIn, setWaitingForEmailSignIn] = useState(false);
  const [hasPasswordlessSignInUrl, setHasPasswordlessSignInUrl] = useState(false);
  const [emailAddress, setEmailAddress] = useState('');
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);
  const [isValidMail, setIsValidMail] = useState(false);
  const [redirectUrl, setRedirectUrl] = useState<string>('');

  const setWebflowCookie = () => {
    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    UserService.userSetWebflowAuthCookie();
    // check whether the user has plan or no
    if (redirectUrl) {
      window.location.href = redirectUrl;
    }
  };

  const handleGoogleSignIn = () => {
    setLoading(true);
    setWaitingForSocialSignIn(true);

    useAuthStore
      .getState()
      .signInWithGoogle()
      .finally(() => {
        setWaitingForSocialSignIn(false);
        setLoading(false);
        setWebflowCookie();
      })
      .catch(() => setError('Please try again'));
  };

  const completePasswordlessSignIn = (emailAddress: string) => {
    setLoading(true);
    setWaitingForEmailSignIn(true);
    useAuthStore
      .getState()
      .completePasswordlessSignIn(emailAddress, window.location.href)
      .finally(() => {
        setWebflowCookie();
        setLoading(false);
      })
      .catch(() => {
        setError('Please confirm your email address');
        setHasPasswordlessSignInUrl(false);
        setWaitingForEmailSignIn(false);
      });
  };

  const handleEmailSignIn = () => {
    if (hasPasswordlessSignInUrl) {
      return completePasswordlessSignIn(emailAddress);
    }
    if (emailAddress) {
      const currentUriBase = window.location.origin.toString();
      const redirectUrl = `${currentUriBase}/login?${PASSWORDLESS_QUERY_PARAM}=1`;
      localStorage.setItem(PASSWORDLESS_SIGN_IN_LOCAL_STORAGE_EMAIL_KEY, emailAddress);

      setLoading(true);
      setError('');
      setWaitingForEmailSignIn(true);

      useAuthStore
        .getState()
        .createPasswordlessSignInLink(emailAddress, redirectUrl)
        .finally(() => {
          setLoading(false);
        })
        .catch(() => setError('There was an error signing in. Please try again'));
    }
  };

  const handleEmailEnter = (e: KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter' && isValidMail) {
      handleEmailSignIn();
    }
  };

  const handleEmailChange = (e: ChangeEvent<HTMLInputElement>) => {
    const email = (e.target as HTMLInputElement).value;
    setEmailAddress(email);
    // Don't use emailAddress directly below, as it's not updated immediately.
    setIsValidMail(emailValidator.validate(email));
  };

  useEffect(() => {
    if (searchParams.get(PASSWORDLESS_QUERY_PARAM)) {
      setHasPasswordlessSignInUrl(true);
      const storedEmailAddress = localStorage.getItem(PASSWORDLESS_SIGN_IN_LOCAL_STORAGE_EMAIL_KEY);

      if (storedEmailAddress) {
        localStorage.removeItem(PASSWORDLESS_SIGN_IN_LOCAL_STORAGE_EMAIL_KEY);

        setEmailAddress(storedEmailAddress);
        completePasswordlessSignIn(storedEmailAddress);
      }
    }
  }, []);
  useEffect(() => {
    const redirectUrlQueryParam = searchParams.get('redirect');
    if (redirectUrlQueryParam && redirectUrlQueryParam !== '') {
      setRedirectUrl(redirectUrlQueryParam);
    } else {
      setRedirectUrl('');
    }
  });

  return (
    <div className="border pt-6 mt-4 rounded-md w-full text-gray-500">
      <div className="px-4">
        <button
          onClick={handleGoogleSignIn}
          disabled={loading || waitingForSocialSignIn}
          className="w-full border rounded-md shadow-md py-2 px-3 text-white bg-label-primary"
        >
          <div className="flex items-center justify-center leading-none tracking-normal">
            <span className="font-semibold">Continue with Google</span>
          </div>
        </button>
        <div className="mt-4">
          <div className="mb-1 font-medium">Email:</div>
          <input
            className="w-full h-8 border rounded-md px-2 py-1
           placeholder:font-light placeholder:text-gray-300 text-gray-600"
            disabled={waitingForEmailSignIn}
            defaultValue={emailAddress}
            onChange={handleEmailChange}
            onBlur={handleEmailChange}
            onKeyDown={handleEmailEnter}
            type="email"
            placeholder="example@mail.com"
          />
          {error ? <span className="text-red-600 text-xs">{error}</span> : <></>}
          {hasPasswordlessSignInUrl ? (
            <span className="text-xs">Please confirm your email address</span>
          ) : (
            <></>
          )}
        </div>
        <div className="mt-4 text-xs text-gray-400">
          By continuing, you agree to our{' '}
          <a className="underline" href={TERMS_AND_CONDITIONS_URL}>
            Terms
          </a>{' '}
          and acknowledge our{' '}
          <a className="underline" href={PRIVACY_POLICY_URL}>
            Privacy policy
          </a>
        </div>
      </div>
      {!waitingForEmailSignIn ? (
        <button
          onClick={handleEmailSignIn}
          disabled={!isValidMail}
          className={classNames('w-full mt-6 rounded-b-md p-2', {
            'cursor-not-allowed bg-neutral-100 text-label-tertiary': !isValidMail,
            'text-white bg-system-accent': isValidMail,
          })}
        >
          Continue
        </button>
      ) : (
        <div className="px-6 mt-4 mb-6 font-medium text-center">
          <span className="text-system-green-7 text-base font-medium">
            Please check your inbox for a sign-in link
          </span>
          <div className="mt-4">
            <span
              onClick={() => setWaitingForEmailSignIn(false)}
              className="cursor-pointer underline text-xs"
            >
              Cancel
            </span>
          </div>
        </div>
      )}
    </div>
  );
};

export default BuilderSignInMessage;
